import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/services/common/util.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html'
})
export class ProgramComponent implements OnInit {

  @Input() academics: any;
  @Input() issuer: any;
  showToggle: Object = new Object();
  showHideData: Object = new Object();
  showHideContent: Object = new Object();
  public isreadOnlyView = false;
  
  constructor(
    private util: UtilService,
    private actRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.actRoute.params.subscribe(params => {
      if (params) {
        this.isreadOnlyView = params.accessToken ? true : false;
      }
    });
  }

  /**
   * @description format the date.
   **/
  public formatDate(date) {
    if (date) {
      return this.util.formatDateString(date);
    }
  }


  /**
   * @description show/hide the course for clr builder snapshot versions.
   * @param course the course whose view is which is being toggled.
   */
  public toggleCourse(course: any) {
    course.isHidden = !course.isHidden;
  }


  /**
   * @description show/hide the competency for clr builder snapshot versions.
   * @param competency the competency whose view is which is being toggled.
   */
  public toggleCompetency(competency: any) {
    competency.isHidden = !competency.isHidden
  }
}
