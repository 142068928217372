import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/common/authentication.service';
import { ClrViewerService } from '../../../services/clr-viewer/clr-viewer.service';
import { Router } from '@angular/router';
import { Constants } from '../../../util/constants/constants';
import { UtilService } from '../../../services/common/util.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(auth, router, viewerService, constants, util, toastr, actRoute, afs) {
        this.auth = auth;
        this.router = router;
        this.viewerService = viewerService;
        this.constants = constants;
        this.util = util;
        this.toastr = toastr;
        this.actRoute = actRoute;
        this.afs = afs;
        this.snapshots = [];
        this.isSyncing = false;
        this.sync = {
            date: '',
            time: '',
            zone: '',
        };
        this.autoSyncError = false;
        this.viewButton = 'View More';
        this.isreadOnlyView = false;
        this.showStudentID = false;
        this.showPhoneNum = false;
        this.showEmailID = false;
        this.showEmailBirthDate = false;
        this.showURL = false;
        this.isBuilder = false;
        this.loadBuilder = false;
    }
    /**
     * @description the init function
     * Note : checks for the readonly view if the employer view is opened.
     * This compoent is reused across viewer/builder and share.
     */
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        // check if the page loaded is builder.
        this.isBuilder = this.router.url.indexOf(this.constants.VALUES.ROUTES.BUILDER) === 1 ? true : false;
        this.actRoute.params.subscribe(function (params) {
            if (params) {
                _this.isreadOnlyView = params.accessToken ? true : false;
            }
        });
        //get the last refreshed date and time with the timezone.
        this.sync = this.util.getFormattedDate(this.issuer.lastTriggeredAt);
        //if the page loaded is viewer only the autosync should happen.
        if (this.user && this.user.email && !this.isBuilder) {
            if (JSON.parse(sessionStorage.getItem('fromBuilder'))) {
                sessionStorage.removeItem('fromBuilder');
            }
            else {
                this.syncData();
            }
        }
    };
    /**
     * @description method to sync the data from salesforce to firestore.
     * Note: Once the user is logged in,
     * this will be called automatically or else he/she can do it manually too.
     */
    SidebarComponent.prototype.syncData = function () {
        var _this = this;
        this.isSyncing = true;
        this.autoSyncError = false;
        this.viewerService.syncData(this.constants.VALUES.MAPPING_ID, this.user.email, function (response) {
            _this.isSyncing = false;
            if (response) {
                _this.sync = _this.util.getFormattedDate(response.lastSyncAt);
            }
        }, function (error) {
            //show toast if the sync is un successful. but the data in firestore if any, will be displayed.
            _this.toastr.error(_this.constants.VALUES.ERRORS.SYNC.MESSAGE, _this.constants.VALUES.ERRORS.SYNC.TYPE);
            _this.autoSyncError = true;
            _this.isSyncing = false;
        });
    };
    /**
     * @description update the student contact details
     * @param key the field to be updated
     * @param value the value to be updated
     **/
    SidebarComponent.prototype.editStudentContactDetails = function (key, value) {
        this.issuer.person[value] = this.issuer.person[value].trim();
        this.viewerService.editContactDetails(this.user.uid, key, this.issuer.person[value].trim());
    };
    /**
     * @description redirect to walden tempo portal
     * Note: Once the user is logged out, he/she will be redirected to the initial page.
     */
    SidebarComponent.prototype.redirectToWaldenTempoPortal = function () {
        window.location.href = environment.waldenTempoUrl;
    };
    /**
     * @description Copy the share url to clipboard
     * Note : User can paste this url to the browser and it will load the page.
     */
    SidebarComponent.prototype.copyShareUrlToClipboard = function (snapshotTitle) {
        var accessToken = this.generateUniqueAccessToken();
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.constants.VALUES.CLR_SHARE.URL + accessToken;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.toastr.success("<a>" + this.constants.VALUES.CLR_SHARE.URL + accessToken + "</a>", this.constants.VALUES.SUCCESS.COPY.MESSAGE);
        this.createNewClrShareDoc(accessToken, snapshotTitle);
    };
    /**
     * @description generate unique accesstoken for share url
     */
    SidebarComponent.prototype.generateUniqueAccessToken = function () {
        var tokenString = this.issuer.person.studentId + "-" +
            (Date.now() + "-") +
            (this.issuer.issuer.name + "-") +
            ((this.issuer.person.givenName).toLocaleUpperCase() + "-") +
            ("" + this.issuer.person.familyName.toLocaleUpperCase());
        var uniqueToken = this.util.getSaltedHash(tokenString);
        return uniqueToken;
    };
    /**
     * @description save generatedtoken along with the user details in firestore
     */
    SidebarComponent.prototype.createNewClrShareDoc = function (uniqueToken, snapshotTitle) {
        var expiredate = new Date();
        expiredate.setDate(expiredate.getDate() + 60);
        var doc = {
            createdate: new Date(),
            expiredate: expiredate,
            snapshot: snapshotTitle,
            uid: this.user.uid
        };
        this.viewerService.setDataForCLRShare(this.user.uid, uniqueToken, doc)
            .then(function (result) {
        });
    };
    SidebarComponent.prototype.showExpiredError = function () {
        this.toastr.error(this.constants.VALUES.CLR_SHARE.EXPIRED_MESSAGE.MSG, this.constants.VALUES.CLR_SHARE.EXPIRED_MESSAGE.TYPE);
    };
    /**
     * @description format the date irrespective of timezones.
     * @param date date string to be formatted.
     */
    SidebarComponent.prototype.formatDateString = function (date) {
        if (date) {
            var _day = date.split('-')[2].toString().length === 1 ? ('0' + date.split('-')[2].toString()) : date.split('-')[2].toString();
            var _month = date.split('-')[1].toString().length === 1 ? ('0' + date.split('-')[1].toString()) : date.split('-')[1].toString();
            var _year = date.split('-')[0].toString();
            return _month + '/' + _day + '/' + _year;
        }
        else {
            return '';
        }
    };
    /**
     * @description set data for the CLR snapshot.
     * @param currentUser the current user logged in.
     * @param issuser the copy of the record from the firestore.
     */
    SidebarComponent.prototype.createShareVersion = function (currentUser) {
        var _this = this;
        this.loadBuilder = true;
        var _name = this.records.length < this.constants.VALUES.SNAPSHOTS.SNAPSHOTCOUNT ? 'VERSION 1' : 'VERSION 2';
        this.issuer.person.hideStudentId = true;
        this.issuer.person.hideDOB = true;
        this.issuer.snapshotName = this.generateSnapshotName(_name);
        this.issuer.snapshotTitle = this.constants.VALUES.SNAPSHOTS[_name];
        this.issuer.snapshotExpiry = this.util.setExpiryDate(new Date());
        var _version = {
            name: this.issuer.snapshotName,
            title: this.issuer.snapshotTitle
        };
        this.viewerService.setDataForCLRViewer(this.user.uid, this.issuer, this.constants.VALUES.SNAPSHOTS[_name])
            .then(function (result) {
            sessionStorage.setItem('version', JSON.stringify(_version));
            _this.router.navigate(['/clr-builder']);
        });
    };
    /**
     * @description method to check for the number of versions before creating the share version.
     * Note : The max number of versions created should be 2.
     */
    SidebarComponent.prototype.enableCreateShareButton = function () {
        return this.records.length === this.constants.VALUES.SNAPSHOTS.MAXCOUNT ? false : true;
    };
    SidebarComponent.prototype.generateSnapshotName = function (version) {
        var _date = new Date();
        var _month = (_date.getMonth() + 1).toString().length === 1 ? '0' + (_date.getMonth() + 1).toString() : (_date.getMonth() + 1);
        var _day = _date.getDate().toString().length === 1 ? '0' + _date.getDate() : _date.getDate();
        var _hour = _date.getHours().toString().length === 1 ? '0' + _date.getHours() : _date.getHours();
        var _minute = _date.getMinutes().toString().length === 1 ? '0' + _date.getMinutes() : _date.getMinutes();
        return version + ' ' + _day + '-' +
            this.constants.VALUES.MONTHS_STRING[_month] + '-' +
            _date.getFullYear().toString().substr(2, 3) + ' ' + _hour + ':' + _minute;
    };
    /**
     * @description redirect to CLR Builder
     * @param item name of the CLR snapshot
     */
    SidebarComponent.prototype.redirectToCLRBuilder = function (item) {
        var _version = {
            name: item.snapshotName,
            title: item.snapshotTitle
        };
        sessionStorage.setItem('version', JSON.stringify(_version));
        this.router.navigate(['/clr-builder']);
    };
    SidebarComponent.prototype.getFormattedDateTime = function (type, isExpiry) {
        var _date;
        if (isExpiry) {
            _date = this.util.getFormattedDate(this.issuer.snapshotExpiry ? this.issuer.snapshotExpiry : new Date());
        }
        else {
            _date = this.util.getFormattedDate(this.issuer.lastTriggeredAt ? this.issuer.lastTriggeredAt : new Date());
        }
        switch (type) {
            case 'date':
                return _date.date;
                break;
            case 'time':
                return _date.time;
                break;
            case 'zone':
                return _date.zone;
        }
    };
    return SidebarComponent;
}());
export { SidebarComponent };
