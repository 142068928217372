import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/common/authentication.service';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { UtilService } from '../../services/common/util.service';
import { DataMappingService } from '../../services/clr-viewer/data-mapping.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Constants } from '../../util/constants/constants';
import { ToastrService } from 'ngx-toastr';
var ClrViewerComponent = /** @class */ (function () {
    function ClrViewerComponent(auth, router, viewerService, dataMappingService, constants, toastr, util) {
        var _this = this;
        this.auth = auth;
        this.router = router;
        this.viewerService = viewerService;
        this.dataMappingService = dataMappingService;
        this.constants = constants;
        this.toastr = toastr;
        this.util = util;
        this.errors = {
            noRecords: false,
            text: ''
        };
        this.showAcademicsSection = false;
        this.util.loader.subscribe(function (value) {
            _this.isLoading = value;
        });
    }
    /**
     * @description The component initialization
     * Note: will check if the user is logged in or not.
     * If yes, the details of the current user is fetched from the firebase auth
     * and is saved to the currentUser object.
     */
    ClrViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.errors.noRecords = false;
        this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
        var _self = this;
        localStorage.removeItem('backToCLR');
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                _self.currentUser = user;
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
                    sessionStorage.setItem('idToken', idToken);
                    _self.getDataForClrViewer(_self.currentUser.uid);
                });
            }
        });
        this.util.loader.subscribe(function (value) {
            _this.isLoading = value;
        });
    };
    /**
     * @description method to sync the data from salesforce to firestore.
     * Note: Once the user is logged in,
     * this will be called automatically or else he/she can do it manually too.
     */
    ClrViewerComponent.prototype.syncData = function () {
        var _this = this;
        this.viewerService.syncData(this.constants.VALUES.MAPPING_ID, this.currentUser.email, function (response) {
        }, function (error) {
            _this.toastr.error(_this.constants.VALUES.ERRORS.SYNC.MESSAGE, _this.constants.VALUES.ERRORS.SYNC.TYPE);
        });
    };
    /**
     * @description Subscribing to the data for binding the details
     * in the CLR Viewer.
     * Note: Once any update happens to the data in the firebase db,
     * the UI will be refreshed automatically with the new changes.
     */
    ClrViewerComponent.prototype.getDataForClrViewer = function (currentUser) {
        var _this = this;
        this.viewerService.getDataForCLRViewer(currentUser, this.constants.VALUES.FIRESTORE_DB_CONSTANTS.MASTER)
            .subscribe(function (result) {
            _this.isLoading = result ? false : true;
            _this.issuers = result ? result[0] : [];
            _this.records = result ? result : [];
            console.info('records from firebase : ', result);
            if (_this.issuers && (_this.issuers.records && _this.issuers.records.length > 0)) {
                _this.issuers.person.ex_userInputUrl === 'undefined' ? _this.issuers.person.ex_userInputUrl = '' : _this.issuers.person.ex_userInputUrl;
                _this.academics = _this.dataMappingService.mapAcademicsData(_this.issuers);
                console.info('formatted records from frontend : ', _this.academics);
                _this.issuers.transcriptEntities.academics = _this.academics;
                _this.showAcademicsSection = _this.academics.isActive ? true : false;
                _this.errors.noRecords = true;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_RECORD;
            }
            else if (!_this.issuers || _this.issuers.length === 0) {
                _this.errors.noRecords = false;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_DATA;
                _this.showAcademicsSection = false;
                _this.isLoading = false;
                _this.syncData();
            }
        }, function (error) {
            _this.syncData();
        });
    };
    return ClrViewerComponent;
}());
export { ClrViewerComponent };
