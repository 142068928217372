import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class APIConstants {

    public BASE_URL = environment.cloudFunctionBaseUrl;
    public API_URL = {
        LOGGER: this.BASE_URL + 'rest/log',
        SYNC: this.BASE_URL + 'v1/rest/refresh/',
    }

    public generateSyncUrl(mappingId, email): string{
        return this.API_URL.SYNC + mappingId + '/data/' + email;
    }
}