import { AngularFirestore } from '@angular/fire/firestore';
import { Constants } from '../../util/constants/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../../util/constants/constants";
var DataMappingService = /** @class */ (function () {
    function DataMappingService(firestore, constants) {
        this.firestore = firestore;
        this.constants = constants;
    }
    /**
     * @description get the issuers collection from firestore
     * Note: This collection will be subscribed from the clr viewer component
     * and the data will be populated in the UI.
     */
    DataMappingService.prototype.mapAcademicsData = function (issuerData) {
        var _data = {
            degrees: [],
            certificates: [],
            courses: [],
            competencies: [],
            skills: []
        };
        var completedCompetencies = [];
        if (issuerData && issuerData.transcriptEntities) {
            _data = {
                degrees: issuerData.transcriptEntities.degrees,
                certificates: issuerData.transcriptEntities.certificates,
                courses: issuerData.transcriptEntities.courses,
                competencies: issuerData.transcriptEntities.competencies,
                skills: []
            };
        }
        // map the records to degrees, certificates, courses and competencies respectively.
        issuerData.records.forEach(function (item) {
            if (item.recordOf) {
                if (item.recordOf.entityType === 'Degree') {
                    _data.degrees.forEach(function (degree) {
                        if (item.recordOf.entityId === degree.id || degree.id.includes(item.recordOf.entityId)) {
                            degree['status'] = item.status;
                            degree['date'] = item.date ? item.date : null;
                            degree['courses'] = [];
                        }
                    });
                }
                else if (item.recordOf.entityType === 'Certificate') {
                    _data.certificates.forEach(function (certificate) {
                        if (item.recordOf.entityId === certificate.id || certificate.id.includes(item.recordOf.entityId)) {
                            certificate['status'] = item.status ? item.status : null;
                            certificate['date'] = item.date ? item.date : null;
                            certificate['%ProgramCompletion'] = item['%ProgramCompletion'] ? item['%ProgramCompletion'] : null;
                            certificate['GPA'] = item.GPA ? item.GPA : null;
                            certificate['courses'] = [];
                        }
                    });
                }
                else if (item.recordOf.entityType === 'Course') {
                    _data.courses.forEach(function (course) {
                        if (item.recordOf.entityId === course.id) {
                            course['credits'] = item.credits ? item.credits : null;
                            course['result'] = item.result ? item.result : null;
                            course['status'] = item.status ? item.status : null;
                            course['competencies'] = [];
                        }
                    });
                }
                else if (item.recordOf.entityType === 'Competency') {
                    _data.competencies.forEach(function (competency) {
                        if (item.recordOf.entityId === competency.id) {
                            competency['completedDate'] = item.completedDate ? item.completedDate : null;
                            competency['result'] = item.result ? item.result : null;
                            competency['status'] = item.status ? item.status : null;
                            competency['subCompetencies'] = competency.subCompetencies ?
                                competency.subCompetencies.filter(function (x) { return x.text != ''; }) :
                                (competency.subCompetencyData ? competency.subCompetencyData.filter(function (x) { return x.text != ''; }) : []);
                        }
                    });
                    if (item.completedDate !== null) {
                        completedCompetencies.push(item.id);
                    }
                }
            }
        });
        //nest competencies under course.
        issuerData.transcriptEntities.competencies.forEach(function (competency) {
            if (competency.competencyType === 'Academic') {
                competency.associations.forEach(function (association) {
                    if (association.entityType === 'Course') {
                        _data.courses.forEach(function (course) {
                            if (association.entityId === course.id) {
                                if (competency.status && (competency.status.completed === 'Achieved' || competency.status.completed === 'Mastered'))
                                    course.competencies.push(competency);
                            }
                        });
                    }
                    else if (association.entityType === 'Competency') {
                        _data.competencies.forEach(function (competency) {
                            if (association.entityId === competency.id) {
                                competency.subCompetencies.push(competency);
                            }
                        });
                    }
                });
            }
            else if (competency.competencyType === 'Skill') {
                if (competency.status && competency.status.completed === 'Achieved') {
                    _data.skills.push(competency);
                }
            }
        });
        //nest courses under degree and certificate.
        issuerData.transcriptEntities.courses.forEach(function (course) {
            course.associations.forEach(function (association) {
                if (association.entityType === 'Degree') {
                    _data.degrees.forEach(function (degree) {
                        if (association.entityId === degree.id) {
                            degree.courses.push(course);
                        }
                        else if (degree.id.includes(association.entityId)) {
                            degree.courses.push(course);
                        }
                    });
                }
                else if (association.entityType === 'Certificate') {
                    _data.certificates.forEach(function (certificate) {
                        if (association.entityId === certificate.id) {
                            certificate.courses.push(course);
                        }
                        else if (certificate.id.includes(association.entityId)) {
                            certificate.courses.push(course);
                        }
                    });
                }
            });
        });
        // returns the object which contains the list of programs, skills 
        // and a flag stating if the student has atleast one competency in achieved or mastered status.
        return {
            programList: _data.degrees.concat(_data.certificates),
            skills: _data.skills,
            isActive: completedCompetencies.length > 0 ? true : false
        };
    };
    DataMappingService.ngInjectableDef = i0.defineInjectable({ factory: function DataMappingService_Factory() { return new DataMappingService(i0.inject(i1.AngularFirestore), i0.inject(i2.Constants)); }, token: DataMappingService, providedIn: "root" });
    return DataMappingService;
}());
export { DataMappingService };
