import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.stg';
import { Constants } from '../../../util/constants/constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  @Input() message: any;
  @Input() errorMessage;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private constants: Constants
  ) { }

  ngOnInit() {

  }

  /**
   * @description close the current tab since the user
   * doesnt have any compentencies completed.
   */
  public closeTab() {
    window.location.href = environment.waldenTempoUrl;
  }

  public reloadPage() {
    location.reload();
  }

}
