import { OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/common/util.service';
import { ActivatedRoute } from '@angular/router';
var ProgramComponent = /** @class */ (function () {
    function ProgramComponent(util, actRoute) {
        this.util = util;
        this.actRoute = actRoute;
        this.showToggle = new Object();
        this.showHideData = new Object();
        this.showHideContent = new Object();
        this.isreadOnlyView = false;
    }
    ProgramComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actRoute.params.subscribe(function (params) {
            if (params) {
                _this.isreadOnlyView = params.accessToken ? true : false;
            }
        });
    };
    /**
     * @description format the date.
     **/
    ProgramComponent.prototype.formatDate = function (date) {
        if (date) {
            return this.util.formatDateString(date);
        }
    };
    /**
     * @description show/hide the course for clr builder snapshot versions.
     * @param course the course whose view is which is being toggled.
     */
    ProgramComponent.prototype.toggleCourse = function (course) {
        course.isHidden = !course.isHidden;
    };
    /**
     * @description show/hide the competency for clr builder snapshot versions.
     * @param competency the competency whose view is which is being toggled.
     */
    ProgramComponent.prototype.toggleCompetency = function (competency) {
        competency.isHidden = !competency.isHidden;
    };
    return ProgramComponent;
}());
export { ProgramComponent };
