/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./error.component";
import * as i3 from "../../../services/common/authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "../../../util/constants/constants";
var styles_ErrorComponent = [];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-goBack"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Refresh"]))], null, null); }
export function View_ErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "section", [["class", "logout-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "logout-bckgrnd-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "logout-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "logo-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/logo_white.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "logout-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["class", "btn-goBack"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeTab() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 6, 0, currVal_0); }); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i2.ErrorComponent, [i3.AuthenticationService, i4.Router, i5.Constants], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i0.ɵccf("app-error", i2.ErrorComponent, View_ErrorComponent_Host_0, { message: "message", errorMessage: "errorMessage" }, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
