import { Router } from '@angular/router';
import { take, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    /**
     * @description check if the current route should be enabled based on the user authentication.
     * @returns info if the user is authenticated or not.
     */
    AuthGuardService.prototype.canActivate = function () {
        var _this = this;
        return this.auth.authInfo
            .pipe(take(1), map(function (user) { return !!user; }), tap(function (signedIn) {
            if (!signedIn) {
                _this.auth.login(function (response) { });
            }
        }));
    };
    return AuthGuardService;
}());
export { AuthGuardService };
