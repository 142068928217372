<app-loader *ngIf="loadBuilder"></app-loader>
<div class="side-bar">
    <div class="custom-card">
        <div class="header">
            <button (click)="redirectToWaldenTempoPortal()" id="redirectToWaldenTempoPortalMobile" type="button" class="btn-grey-top">
                <span class="icon-ic_logout btn-logout"></span>
            </button>
            <!-- <img src="assets/images/logo_white.svg" class="logo" /> -->
            <img [src]="issuer.issuer.logo" class="logo" />
        </div>
        <div class="profile-pic">
            <img src="assets/images/profile.png" />
        </div>
        <div class="profile-name">
            <h3 class="profile-heading">{{issuer.person.fullName}}</h3>
            <p class="light-medium" *ngIf="!isreadOnlyView || (isreadOnlyView && !issuer.person.hideStudentId)">
                <i id="toggleStudentId" class="show-hide" (click)="issuer.person.hideStudentId= !issuer.person.hideStudentId"
                    [ngClass]="{'icon-ic_show_sb':issuer.person.hideStudentId,'icon-ic_hide_sb':!issuer.person.hideStudentId}"></i>
                <span [ngClass]="{'light-font':issuer.person.hideStudentId}">{{issuer.person.studentId}}</span>
                <!-- <span [ngClass]="{'light-font':issuer.person.hideStudentId}">12345678</span> -->
                
            </p>

            <ng-container *ngFor="let item of records">
                <button class="btn-version" type="button" *ngIf="item.snapshotName">
                    <span class="version-name"  title="{{item.snapshotName}}">{{item.snapshotName}}</span>
                    <span class="edit-share-wrapper">
                    <span id="editVersion-1" class="lorateicon icon-ic_edit-title" (click)="redirectToCLRBuilder(item)"></span>
                    <span id="copyUrl" class="lorateicon icon-ic_share" (click)="copyShareUrlToClipboard(item.snapshotTitle)"></span>
                </span>
                </button>
            </ng-container>
            <button id="createShareVersion" class="btn-share" type="button" (click)="createShareVersion()" *ngIf="enableCreateShareButton()"> 
                <span aria-hidden="true" class="lorateicon icon-ic_add_blue"></span>
                create a share version
            </button>
        </div>
    </div>
    <div class="collapse collapse-profile">
        <div class="profile-details">
            <div class="form-group" *ngIf="!isreadOnlyView || (isreadOnlyView && !issuer.person.hidePhone)">
                <i id="togglePhone" class="show-hide" (click)="issuer.person.hidePhone= !issuer.person.hidePhone"
                    [ngClass]="{'icon-ic_show_sb':issuer.person.hidePhone,'icon-ic_hide_sb':!issuer.person.hidePhone}"></i>
                <label [ngClass]="{'light-font':issuer.person.hidePhone}">Phone</label>
                <input [ngClass]="{'light-font':issuer.person.hidePhone}" id="phoneNumber" type="text"
                    class="form-control phonenmbr-font" [(ngModel)]="issuer.person.ex_userInputPhone"
                    (blur)="editStudentContactDetails('phone', 'ex_userInputPhone')" mask='000 000 0000' [disabled]="isBuilder || isreadOnlyView"/>
            </div>
            <div class="form-group" title="{{issuer.person.ex_userInputEmail}}" *ngIf="!isreadOnlyView || (isreadOnlyView && !issuer.person.hideEmail)">
                <i id="toggleEmail" class="show-hide" (click)="issuer.person.hideEmail= !issuer.person.hideEmail"
                    [ngClass]="{'icon-ic_show_sb':issuer.person.hideEmail,'icon-ic_hide_sb':!issuer.person.hideEmail}"></i>
                <label [ngClass]="{'light-font':issuer.person.hideEmail}"> EMAIL </label>
                <input [ngClass]="{'light-font':issuer.person.hideEmail}" id="emailAddress" type="text"
                    class="form-control blue-font email-font" [(ngModel)]="issuer.person.ex_userInputEmail"
                    (blur)="editStudentContactDetails('email', 'ex_userInputEmail')" [disabled]="isBuilder || isreadOnlyView"/>
            </div>
            <div class="form-group no-border" *ngIf="!isreadOnlyView || (isreadOnlyView && !issuer.person.hideDOB)">
                <i id="toggleBirthDate" class="show-hide" (click)="issuer.person.hideDOB= !issuer.person.hideDOB"
                    [ngClass]="{'icon-ic_show_sb':issuer.person.hideDOB,'icon-ic_hide_sb':!issuer.person.hideDOB}"></i>
                <label [ngClass]="{'light-font':issuer.person.hideDOB}"> BIRTH DATE </label>
                <p [ngClass]="{'light-font':issuer.person.hideDOB}" id="birthDate">{{formatDateString(issuer.person.birthDate)}}
                </p>
            </div>
            <ng-container *ngIf="!isreadOnlyView || (isreadOnlyView && !issuer.person.hideUrl)">
                <div class="tooltip-wrapper input-tooltip" title="{{issuer.person.ex_userInputUrl}}"
                    [hidden]="(isreadOnlyView || isBuilder) && !issuer.person.ex_userInputUrl">
                    <div class="form-group">
                        <i id="toggleUrl" class="show-hide" (click)="issuer.person.hideUrl= !issuer.person.hideUrl"
                            [ngClass]="{'icon-ic_show_sb':issuer.person.hideUrl,'icon-ic_hide_sb':!issuer.person.hideUrl}"></i>
                        <label [ngClass]="{'light-font':issuer.person.hideUrl}"> url </label>
                        <input [ngClass]="{'light-font':issuer.person.hideUrl}" id="userUrl" type="text"
                            class="form-control blue-font url-font" [(ngModel)]="issuer.person.ex_userInputUrl"
                            placeholder="Please enter url here"
                            (blur)="editStudentContactDetails('url', 'ex_userInputUrl')" [disabled]="isBuilder || isreadOnlyView"/>
                    </div>
                    <div class="tooltiptext">
                        Do you have an external profile (eg. LinkedIn URL) that you want to share with employers?
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="profile-sync viewer-profile">
            <ng-container *ngIf="issuer.lastTriggeredAt">
                <!-- <h6>last sync</h6> -->
                <h6>Last Refreshed</h6>
                <div class="date-time">
                    <div class="flex-baseline">
                        <span class="date-update">{{sync.date}}</span>
                        <span class="time-update"> {{sync.time}} {{sync.zone}}</span>
                    </div>
                    <div class="tooltip-wrapper" *ngIf="autoSyncError">
                        <img src="assets/images/ic_alert-red.svg" />
                        <div class="tooltiptext">Oops your refresh didn't work! Please click on refresh to get the
                            latest record. If you face this error too often please contact tempoperations@laureate.net
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isBuilder">
                <button id="sync-Data" type="button" class="btn-noborder" *ngIf="!isSyncing" (click)="syncData()">
                    <span aria-hidden="true" class="icon-iconfinder lorateicon"></span>
                    <span class="refresh">Refresh Academic Data</span>
                </button>
                <button type="button" class="btn-noborder no-pointer" *ngIf="isSyncing">
                    <img src="assets/images/ic_sync.svg" class="sync-loader" />
                    <span class="refresh"> Refreshing Academic Data... </span>
                </button>
            </ng-container>
        </div>


        <div class="profile-sync builder-profile">
            <ng-container *ngIf="issuer.lastTriggeredAt">
                <h6>Last Refreshed</h6>
                <div class="date-time">
                    <div class="flex-baseline">
                        <span class="date-update">{{getFormattedDateTime('date', false)}}</span>
                        <span class="time-update"> {{getFormattedDateTime('time', false)}} {{getFormattedDateTime('zone', false)}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    
        

        <div class="profile-sync profile-expire">
            <h6>Expires On</h6>
            <div class="date-time">
                <div class="flex-baseline">
                    <span class="date-update">{{getFormattedDateTime('date', true)}}</span>
                    <span class="time-update"> {{getFormattedDateTime('time', true)}} {{getFormattedDateTime('zone', true)}}</span>
                </div>
            </div>
        </div>

    </div>
    <button id="viewCollapse" type="button" data-toggle="collapse" data-target=".collapse-profile" class="btn-view"
        (click)="viewButton = viewButton === 'View More' ? 'View Less' : 'View More'">
        <span aria-hidden="true" [ngClass]="{'icon-ic_chev-up' : viewButton === 'View Less'}"></span>
        {{viewButton}}
        <span aria-hidden="true" [ngClass]="{'icon-ic_chev-down' : viewButton === 'View More'}"></span>
    </button>
    <button (click)="redirectToWaldenTempoPortal()" id="redirectToWaldenTempoPortal" type="button" class="btn-grey exit-record-btn">
        <span aria-hidden="true" class="icon-ic_logout btn-logout"></span>
        EXIT MY RECORD</button>
</div>