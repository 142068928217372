// Import Firebase and AngularFire
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { LoggerService } from './logger.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "./logger.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(afAuth, logger) {
        this.afAuth = afAuth;
        this.logger = logger;
        this.authInfo = this.afAuth.authState;
    }
    /**
     * @description method to authenticate the user based on the service selected.
     * Note: The authentication service can be anything. (microsoft.com, google.com... etc).
     * It can be configured.
     */
    AuthenticationService.prototype.login = function (successCallback) {
        var _this = this;
        var provider = new firebase.auth.OAuthProvider(environment.authService);
        provider.setCustomParameters({
            tenant: environment.tenandId,
            domain_hint: environment.domainHint
        });
        this.afAuth.auth.signInWithRedirect(provider)
            .then(function (user) {
            _this.logger.log('login successful.', 'info', function (response) { }, function (error) { });
            successCallback(user);
        });
    };
    /**
     * @description method to logout the user based on the service selected.
     */
    AuthenticationService.prototype.logout = function (successsCallback) {
        this.afAuth.auth.signOut().then(function () {
            successsCallback(true);
        });
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.AngularFireAuth), i0.inject(i2.LoggerService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
