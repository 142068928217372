import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoggerService } from './services/common/logger.service';
import { UtilService } from './services/common/util.service';
import { ApicallService } from './util/apicall.service';
import { Constants } from './util/constants/constants';
import { Timezone } from './util/constants/timezone';
import { APIConstants } from './util/constants/apiconstants';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';

import { AuthenticationComponent } from './components/authentication/authentication.component';
import { ClrViewerComponent } from './components/clr-viewer/clr-viewer.component';

import { AuthenticationService } from './services/common/authentication.service';
import { ClrViewerService } from './services/clr-viewer/clr-viewer.service';
import { DataMappingService } from './services/clr-viewer/data-mapping.service';
import { AuthGuardService } from './services//common/auth-guard.service';

import { AppRoutingModule } from './app-routing.module';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { ProgramComponent } from './components/common/program/program.component';
import { SkillsComponent } from './components/common/skills/skills.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ErrorComponent } from './components/common/error/error.component';
import { ClrBuilderComponent } from './components/clr-builder/clr-builder.component';
import { ClrShareComponent } from './components/clr-share/clr-share.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    ClrViewerComponent,
    SidebarComponent,
    ProgramComponent,
    SkillsComponent,
    LoaderComponent,
    ErrorComponent,
    ClrBuilderComponent,
    ClrShareComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    BrowserAnimationsModule,
    HttpModule,
    CarouselModule,
    FormsModule,
    ToastrModule.forRoot({
      enableHtml: true,
      positionClass: 'toast-bottom-center'
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [ApicallService, LoggerService, Constants, AuthenticationService,
    AuthGuardService, ClrViewerService, APIConstants, DataMappingService, UtilService, Timezone],
  bootstrap: [AppComponent]
})
export class AppModule { }
