import { AngularFirestore } from '@angular/fire/firestore';
import { ApicallService } from '../../util/apicall.service';
import { Constants } from '../../util/constants/constants';
import { APIConstants } from '../../util/constants/apiconstants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../../util/apicall.service";
import * as i3 from "../../util/constants/constants";
import * as i4 from "../../util/constants/apiconstants";
var ClrViewerService = /** @class */ (function () {
    function ClrViewerService(firestore, apicallService, constants, apiConstants) {
        this.firestore = firestore;
        this.apicallService = apicallService;
        this.constants = constants;
        this.apiConstants = apiConstants;
    }
    /**
     * @description get the issuers collection from firestore
     * Note: This collection will be subscribed from the clr viewer component
     * and the data will be populated in the UI.
     */
    ClrViewerService.prototype.getDataForCLRViewer = function (user, docName) {
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
            .doc(user)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
            // .doc(docName)
            .valueChanges();
    };
    /**
   * @description get the snapshot collection from firestore
   * Note: This collection will be subscribed from the clr viewer builder
   * and the data will be populated in the UI.
   */
    ClrViewerService.prototype.getDataForCLRSnapShot = function (user, docName) {
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
            .doc(user)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
            .doc(docName)
            .valueChanges();
    };
    /**
     * @description get the issuers collection from firestore
     * Note: This collection will be subscribed from the clr viewer component
     * and the data will be populated in the UI.
     */
    ClrViewerService.prototype.getCLRShareDetails = function (token) {
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.SHARE.COLLECTION)
            .doc(token)
            .valueChanges();
    };
    ClrViewerService.prototype.setDataForCLRViewer = function (user, issuer, snapshotName) {
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
            .doc(user)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
            .doc(snapshotName).set(issuer);
    };
    ClrViewerService.prototype.setDataForCLRShare = function (user, uniqueToken, doc) {
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection('CLRShare')
            .doc(uniqueToken).set(doc);
    };
    /**
     * @desc method to sync data to firebase.
     * @param mappingId the mappingId.
     * @param email email of the user logged in.
     * @param successCallback success callback function.
     * @param failureCallback failure callback function.
     */
    ClrViewerService.prototype.syncData = function (mappingId, email, successCallback, failureCallback) {
        this.apicallService.doPostAPIAction(this.apiConstants.generateSyncUrl(mappingId, email), {}, function (response) {
            successCallback(JSON.parse(response._body));
        }, function (error) {
            failureCallback(error._body);
        });
    };
    /**
     * @description update the user contact details in the firestore
     * @param user the user whose record is being updated.
     * @param field the field being updated.
     * @param value the updated value to be stored to the firestore.
     */
    ClrViewerService.prototype.editContactDetails = function (user, field, value) {
        var _data = {};
        switch (field) {
            case 'phone':
                _data = { 'person.ex_userInputPhone': value };
                break;
            case 'email':
                _data = { 'person.ex_userInputEmail': value };
                break;
            case 'url':
                _data = { 'person.ex_userInputUrl': value };
                break;
        }
        return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
            .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
            .doc(user)
            .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
            .doc('CLRMaster')
            .update(_data);
    };
    ClrViewerService.ngInjectableDef = i0.defineInjectable({ factory: function ClrViewerService_Factory() { return new ClrViewerService(i0.inject(i1.AngularFirestore), i0.inject(i2.ApicallService), i0.inject(i3.Constants), i0.inject(i4.APIConstants)); }, token: ClrViewerService, providedIn: "root" });
    return ClrViewerService;
}());
export { ClrViewerService };
