import { Router } from '@angular/router';
var AppComponent = /** @class */ (function () {
    function AppComponent(router) {
        // const script = document.createElement('script');
        // script.async = true;
        // script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gtmCode;
        // document.head.appendChild(script);
        this.router = router;
        // const navigationEvent = router.events.pipe(
        //   filter(e => e instanceof NavigationEnd)
        // );
        // navigationEvent.subscribe((e: NavigationEnd) => {
        //   gtag('config', 'UA-142159814-1', {
        //     'page_path': e.urlAfterRedirects
        //   });
        // });
    }
    return AppComponent;
}());
export { AppComponent };
