import { ApicallService } from '../../util/apicall.service';
import { Constants } from '../../util/constants/constants';
import { APIConstants } from '../../util/constants/apiconstants';
import * as i0 from "@angular/core";
import * as i1 from "../../util/apicall.service";
import * as i2 from "../../util/constants/constants";
import * as i3 from "../../util/constants/apiconstants";
var LoggerService = /** @class */ (function () {
    function LoggerService(apicallService, constants, apiConstants) {
        this.apicallService = apicallService;
        this.constants = constants;
        this.apiConstants = apiConstants;
    }
    /**
     * @desc method to log the messages to firebase.
     * @param message the message to be logged.
     * @param type type of the log(ERROR, INFO, DEBUG...etc).
     * @param successCallback success callback function.
     * @param failureCallback failure callback function.
     */
    LoggerService.prototype.log = function (message, type, successCallback, failureCallback) {
        var logRequest = {
            type: this.constants.VALUES.LOG_TYPE[type],
            stacktrace: message,
            location: '',
            app: this.constants.VALUES.APP_NAME
        };
        this.apicallService.doPostAPIAction(this.apiConstants.API_URL.LOGGER, logRequest, function (response) {
            successCallback(response);
        }, function (error) {
            failureCallback(error);
        });
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.inject(i1.ApicallService), i0.inject(i2.Constants), i0.inject(i3.APIConstants)); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
