import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/common/authentication.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-auth',
  templateUrl: './authentication.component.html'
})
export class AuthenticationComponent implements OnInit {

  public isLoading = false;
  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    var _self = this;
    _self.isLoading = JSON.parse(localStorage.getItem('backToCLR')) ? true : false;
  }

  /**
   * @description open the CLR viewer on successfull authentication.
   * Note: The authentication is done using microsoft AD and firebase.
   * User will be directed to the tenants login page and can login 
   * by entering the username and password.
   */
  redirectToCLRViewer() {
    localStorage.setItem('backToCLR', JSON.stringify(true));
    this.router.navigate(['/clr-viewer']);
  }
}
