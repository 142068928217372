import { Component, OnInit } from '@angular/core';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { Constants } from '../../util/constants/constants';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-clr-share',
  templateUrl: './clr-share.component.html'
})
export class ClrShareComponent implements OnInit {

  public currentUser: any;
  public issuers;
  public records;
  public academics;
  public errors = {
    noRecords: false,
    text: ''
  };
  public isLoading = true;
  public showAcademicsSection = false;
  public shareData;

  constructor(
    private viewerService: ClrViewerService,
    private constants: Constants,
    private actRoute: ActivatedRoute
  ) { }


  /**
   * @description The component initialization
   * Note: will check if the user is logged in or not.
   * If yes, the details of the current user is fetched from the firebase auth
   * and is saved to the currentUser object.
   */
  ngOnInit() {
    var _self = this;
    localStorage.removeItem('backToCLR');
    this.errors.noRecords = false;
    this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
    this.actRoute.params.subscribe(params => {
      if (params) {
        this.getCLRShareDetails(params.accessToken);
      }
    });

  }

  public getCLRShareDetails(token) {
    this.viewerService.getCLRShareDetails(token)
    .subscribe(result => {
      if(result) {
        this.shareData = result;
        console.log(this.shareData);
        this.getDataForClrViewer(this.shareData.uid, this.shareData.snapshot);
      }
    });
  }

  /**
   * @description Subscribing to the data for binding the details
   * in the CLR Viewer.
   * Note: Once any update happens to the data in the firebase db,
   * the UI will be refreshed automatically with the new changes.
   */
  public getDataForClrViewer(currentUser, doc) {
    this.viewerService.getDataForCLRSnapShot(currentUser, doc)
      .subscribe(result => {
        this.isLoading = result ? false : true;
        this.issuers = result;
        this.records = [];
        if (this.issuers && (this.issuers.records && this.issuers.records.length > 0)) {
          this.issuers.person.ex_userInputUrl === 'undefined' ? this.issuers.person.ex_userInputUrl = '' : this.issuers.person.ex_userInputUrl
          this.academics = this.getSharedAcademicDetails(this.issuers.transcriptEntities.academics);
          this.showAcademicsSection = this.academics.isActive ? true : false;
          this.errors.noRecords = true;
          this.errors.text = this.constants.VALUES.ERRORS.NO_RECORD;
        } else if (!this.issuers || this.issuers.length === 0) {
          this.errors.noRecords = false;
          this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
          this.showAcademicsSection = false;
          this.isLoading = false;
        }
      });
  }

  /**
   * @description filter the records which are hidden in the shared version
   * and show only the items which are made visible by the student.
   * @param records records to be formatted
   */
  public getSharedAcademicDetails(records) {
    records.programList.forEach(item => {
      item.courses.forEach(course => {
        course.competencies.forEach(competency => {
          if (competency.isHidden) {
            competency.subCompetencies = [];
          }
        });
      });
    });
    return {
      isActive : records.isActive,
      programList : records.programList,
      skills : records.skills.filter(x => !x.isHidden)
    }
  }

}

