import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApicallService } from '../../util/apicall.service';
import { Constants } from '../../util/constants/constants';
import { APIConstants } from '../../util/constants/apiconstants';

@Injectable({
  providedIn: 'root'
})
export class ClrViewerService {


  constructor(
    private firestore: AngularFirestore,
    private apicallService: ApicallService,
    private constants: Constants,
    private apiConstants: APIConstants
  ) {}

  /**
   * @description get the issuers collection from firestore
   * Note: This collection will be subscribed from the clr viewer component
   * and the data will be populated in the UI.
   */
  public getDataForCLRViewer(user, docName) {
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
    .doc(user)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
    // .doc(docName)
    .valueChanges();
  }

    /**
   * @description get the snapshot collection from firestore
   * Note: This collection will be subscribed from the clr viewer builder
   * and the data will be populated in the UI.
   */
  public getDataForCLRSnapShot(user, docName) {
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
    .doc(user)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
    .doc(docName)
    .valueChanges();
  }

  /**
   * @description get the issuers collection from firestore
   * Note: This collection will be subscribed from the clr viewer component
   * and the data will be populated in the UI.
   */
  public getCLRShareDetails(token) { 
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.SHARE.COLLECTION)
    .doc(token)
    .valueChanges();
  }


  public setDataForCLRViewer(user, issuer, snapshotName) {
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
    .doc(user)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
    .doc(snapshotName).set(issuer);
}

  public setDataForCLRShare(user, uniqueToken, doc) {
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection('CLRShare')
    .doc(uniqueToken).set(doc);
  }


  /**
   * @desc method to sync data to firebase.
   * @param mappingId the mappingId.
   * @param email email of the user logged in.
   * @param successCallback success callback function.
   * @param failureCallback failure callback function.
   */
  public syncData(mappingId: String, email: string, successCallback, failureCallback) {
    this.apicallService.doPostAPIAction(this.apiConstants.generateSyncUrl(mappingId, email), {}, (response) => {
      successCallback(JSON.parse(response._body));
    }, (error) => {
      failureCallback(error._body);
    });
  }

  /**
   * @description update the user contact details in the firestore
   * @param user the user whose record is being updated.
   * @param field the field being updated.
   * @param value the updated value to be stored to the firestore.
   */
  public editContactDetails(user, field, value) {
    var _data = {};
    switch(field) {
      case 'phone' : _data = { 'person.ex_userInputPhone' : value };
        break;
      case 'email' : _data = { 'person.ex_userInputEmail' : value };
        break;
      case 'url' : _data = { 'person.ex_userInputUrl' : value };
        break;
    }
    return this.firestore.collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.ROOT)
    .doc(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.TENANT)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.COLLECTION)
    .doc(user)
    .collection(this.constants.VALUES.FIRESTORE_DB_CONSTANTS.DOC)
    .doc('CLRMaster')
    .update(_data);
  }

}
