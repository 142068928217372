import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

declare var gtag;
declare var gtm;

export interface Item { name: string; }

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})

export class AppComponent{
  constructor(
    private router: Router
  ) {
    // const script = document.createElement('script');
    // script.async = true;
    // script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gtmCode;
    // document.head.appendChild(script);
    
    // const navigationEvent = router.events.pipe(
    //   filter(e => e instanceof NavigationEnd)
    // );
    
    // navigationEvent.subscribe((e: NavigationEnd) => {
    //   gtag('config', 'UA-142159814-1', {
    //     'page_path': e.urlAfterRedirects
    //   });
    // });
  }
}