import { Component, OnInit, ɵConsole } from '@angular/core';
import { AuthenticationService } from '../../services/common/authentication.service';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { UtilService } from '../../services/common/util.service';
import { DataMappingService } from '../../services/clr-viewer/data-mapping.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Constants } from '../../util/constants/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clr-viewer',
  templateUrl: './clr-viewer.component.html'
})
export class ClrViewerComponent implements OnInit {

  public currentUser: any;
  public issuers;
  public records;
  public academics;
  public errors = {
    noRecords: false,
    text: ''
  };
  public isLoading;
  public showAcademicsSection = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private viewerService: ClrViewerService,
    private dataMappingService: DataMappingService,
    private constants: Constants,
    private toastr: ToastrService,
    private util: UtilService
  ) { 
    this.util.loader.subscribe(value => {
      this.isLoading = value;
    });
  }


  /**
   * @description The component initialization
   * Note: will check if the user is logged in or not.
   * If yes, the details of the current user is fetched from the firebase auth
   * and is saved to the currentUser object.
   */
  ngOnInit() {
    this.errors.noRecords = false;
    this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
    var _self = this;
    localStorage.removeItem('backToCLR');
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        _self.currentUser = user;
        firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
          sessionStorage.setItem('idToken', idToken);
          _self.getDataForClrViewer(_self.currentUser.uid);
        });
      }
    });

    this.util.loader.subscribe(value => {
      this.isLoading = value;
    });
  }

  /**
   * @description method to sync the data from salesforce to firestore.
   * Note: Once the user is logged in,
   * this will be called automatically or else he/she can do it manually too.
   */
  public syncData() {
    this.viewerService.syncData(this.constants.VALUES.MAPPING_ID, this.currentUser.email, response => {
    }, error => {
      this.toastr.error(this.constants.VALUES.ERRORS.SYNC.MESSAGE, this.constants.VALUES.ERRORS.SYNC.TYPE);
    });
  }

  /**
   * @description Subscribing to the data for binding the details
   * in the CLR Viewer.
   * Note: Once any update happens to the data in the firebase db,
   * the UI will be refreshed automatically with the new changes.
   */
  public getDataForClrViewer(currentUser) {
    this.viewerService.getDataForCLRViewer(currentUser, this.constants.VALUES.FIRESTORE_DB_CONSTANTS.MASTER)
      .subscribe(result => {
        this.isLoading = result ? false : true;
        this.issuers = result ? result[0] : [];
        this.records = result ? result : [];
        console.info('records from firebase : ', result);
        if (this.issuers && (this.issuers.records && this.issuers.records.length > 0)) {
          this.issuers.person.ex_userInputUrl === 'undefined' ? this.issuers.person.ex_userInputUrl = '' : this.issuers.person.ex_userInputUrl;
          this.academics = this.dataMappingService.mapAcademicsData(this.issuers);
          console.info('formatted records from frontend : ', this.academics)
          this.issuers.transcriptEntities.academics = this.academics;
          this.showAcademicsSection = this.academics.isActive ? true : false;
          this.errors.noRecords = true;
          this.errors.text = this.constants.VALUES.ERRORS.NO_RECORD;
        } else if (!this.issuers || this.issuers.length === 0) {
          this.errors.noRecords = false;
          this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
          this.showAcademicsSection = false;
          this.isLoading = false;
          this.syncData();
        }
      }, error => {
        this.syncData();
      });
  }
}
