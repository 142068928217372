<app-loader *ngIf="isLoading"></app-loader>
<section class="logout-wrapper" *ngIf="!isLoading">
    <div class="logout-bckgrnd-img"></div>
    <div class="logout-inner">
        <div class="logo-img">
            <img class="logo" src="assets/images/logo_white.svg">
        </div>        
        <p class="logout-content">
            You have exited from your record. Click on "Go Back" to visit your record again.
        </p>
        <button class="btn-goBack" (click)="redirectToCLRViewer()">go back</button>
    </div>
</section>