import { environment } from '../../../environments/environment';
var Constants = /** @class */ (function () {
    function Constants() {
        this.VALUES = {
            APP_NAME: 'LaureateCLR APP',
            FIRESTORE_DB_CONSTANTS: {
                ROOT: 'Issuers',
                TENANT: 'Walden',
                COLLECTION: 'Persons',
                DOC: 'CLR',
                MASTER: 'CLRMaster',
                SHARE: {
                    COLLECTION: 'CLRShare'
                }
            },
            LOG_TYPE: {
                "error": 'ERROR',
                "debug": 'DEBUG',
                "info": 'INFO'
            },
            MAPPING_ID: 'waldenTempo',
            MONTHS: {
                'Jan': 'January',
                'Feb': 'February',
                'Mar': 'March',
                'Apr': 'April',
                'May': 'May',
                'Jun': 'June',
                'Jul': 'July',
                'Aug': 'August',
                'Sep': 'September',
                'Oct': 'October',
                'Nov': 'November',
                'Dec': 'December'
            },
            MONTHS_IN_NUMBERS: {
                '01': 'January',
                '02': 'February',
                '03': 'March',
                '04': 'April',
                '05': 'May',
                '06': 'June',
                '07': 'July',
                '08': 'August',
                '09': 'September',
                '10': 'October',
                '11': 'November',
                '12': 'December'
            },
            MONTHS_STRING: {
                '01': 'JAN',
                '02': 'FEB',
                '03': 'MAR',
                '04': 'APR',
                '05': 'MAY',
                '06': 'JUN',
                '07': 'JUL',
                '08': 'AUG',
                '09': 'SEP',
                '10': 'OCT',
                '11': 'NOV',
                '12': 'DEC'
            },
            ERRORS: {
                SYNC: {
                    TYPE: 'Sync Error',
                    MESSAGE: "Oops -- Your refresh didn\u2019t work! Please click on Refresh to get the \n                latest record. If this error continues, please contact tempooperations@laureate.net"
                },
                NO_RECORD: "Oops! It looks like you haven't completed any competencies. Please check back later.",
                NO_DATA: 'Please wait while we setup your record'
            },
            SUCCESS: {
                COPY: {
                    TYPE: 'Copy Link',
                    MESSAGE: 'Link copied to clipboard for share.'
                }
            },
            CLR_SHARE: {
                URL: environment.CLRShare.clrUrl + environment.CLRShare.profileUrl + environment.CLRShare.indicator,
                SALT: 'CLR',
                EXPIRED_MESSAGE: {
                    TYPE: 'Version Expired.',
                    MSG: 'This version is not shareable because it is more than 60 days old'
                    // MSG : 'This version is not shareable because it is more than 60 days old. You must refresh to share this version.'
                }
            },
            FIXED_SALT: 'CLR',
            ROUTES: {
                VIEWER: 'clr-viewer',
                BUILDER: 'clr-builder',
            },
            SNAPSHOTS: {
                'VERSION 1': 'CLRSnapshot001',
                'VERSION 2': 'CLRSnapshot002',
                MAXCOUNT: 3,
                SNAPSHOTCOUNT: 2
            }
        };
    }
    return Constants;
}());
export { Constants };
