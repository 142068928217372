<app-loader *ngIf="isLoading"></app-loader>
<div id="wrapper" *ngIf="issuers && !isLoading && showAcademicsSection">
    <div class="container-fluid">
        <div class="original-version"> original version </div>
        <div class="outer-wrapper">
            <div class="left-section">
                <app-sidebar [user]="currentUser" [issuer]="issuers" [records]="records"></app-sidebar>
            </div>
            <div class="right-section detail-container">
                <div class="detailed-section">                
                    <app-skills *ngIf="academics.skills.length > 0" [skills]="academics.skills"></app-skills>
                    <app-program [academics]="academics" [issuer]="issuers"></app-program>
                </div>
            </div>
        </div>
    </div>
</div>
<app-error *ngIf="!issuers || !showAcademicsSection" [message]="errors.noRecords" [errorMessage]="errors.text"></app-error>