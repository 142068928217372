<div class="academic-section phone">
   <h6>ACADEMICS</h6>
   <div class="block-wrapper">
      <ng-container *ngFor="let item of academics.programList" >
      <div class="degree-block" *ngIf="item.courses ? item.courses.length > 0 : false"
         [ngClass]="{'certificate-block': item.type === 'Certificate' }">
         <div class="block-header">
            <div class="header-content">
               <h5 class="pre-title pretitle-mob">{{item.type}}</h5>
               <div class="row header-wrapper">
                  <div class="col-md-9 col-sm-12 header-left" id="degreesection1">
                     <h5 class="pre-title pretitle-web">{{item.type}}</h5>
                     <h3 class="block-title">{{item.name}}</h3>
                  </div>
                  <div class="col-md-3 col-sm-12 header-right" id="degreesection2">
                     <h3 class="block-status" *ngIf="item.status && item.status.completed < 100">
                        <span class="lorateicon icon-ic_inprog"></span>
                        <span>IN PROGRESS</span>
                     </h3>
                     <ng-container *ngIf="item.status && item.status.completed >= 100">
                        <h3 class="block-status completed">
                           <span class="lorateicon icon-ic_complete-white"></span>
                           <span>completed</span>
                        </h3>
                        <h5 class="block-date">{{formatDate(item.DegreeConferralDate)}}</h5>
                     </ng-container>
                  </div>
               </div>
            </div>
         </div>
         <!-- courses -->
         <ng-container *ngFor="let course of item.courses; let $index = index;">
            <div class="block-details" *ngIf="course.courseCode && (course.competencies ? course.competencies.length > 0 : false)">
               <div class="block-content">
                  <ul class="heirarchy-one">
                     <li>
                        <div class="flex-position collapse-top-head  heading-main"
                           [ngClass]="{'no-comp': (course && !course.competencies.length) || (isreadOnlyView && course.isHidden)}">

                           <div class="show-hide-wrapper" (click)="toggleCourse(course)"
                              [ngClass]="{'dark-background' : course.isHidden}">
                              <i id="toggleCompetency-{{course.id}}" class="show-hide" [ngClass]="{'icon-ic_hide_sb' : !course.isHidden, 
                                  'icon-ic_show_sb' : course.isHidden}"></i>
                              <p class="show-hide-text">{{course.isHidden ? 'Show' : 'Hide'}}</p>
                           </div>


                           <div class="bullet-container no-padding">
                              <img src="assets/images/mk_course.png" />
                           </div>
                           <div class="flex-align border-width course-share"
                              [ngClass]="{'dark-background' : course.isHidden}">
                              <h4 class="dark-blue-medium sub-title">
                                 <span class="course-code">{{course.courseCode}}</span>
                                 <span class="light-medium title">{{course.name}}</span>
                              </h4>
                              <!-- Display for Web View -->
                              <p class="light-small">
                                 <span *ngIf="course.credits">Credits: <b>{{course.credits | number:'1.2'}}</b></span>
                                 <span *ngIf="course.result">Grade: <b>{{course.result}}</b></span>
                              </p>
                              <!-- Display for Mobile View -->
                              <div class="xs-block">
                                 <span class="light-medium title">{{course.name}}</span>
                              </div>
                           </div>
                        </div>

                        <!-- competencies -->
                        <ul class="heirarchy-two" [ngClass]="{'dark-background' : course.isHidden}" [hidden]="isreadOnlyView && course.isHidden">
                           <ng-container *ngFor="let competency of course.competencies; let j = index;">
                              <li
                                 *ngIf="competency.status && competency.status.completed === 'Achieved' || competency.status.completed === 'Mastered'">
                                 <div class="competency-wrapper">

                                    <div class="show-hide-wrapper" (click)="toggleCompetency(competency)"
                                       [ngClass]="{'dark-background' : competency.isHidden}">
                                       <i id="toggleSubCompetency-{{competency.id}}" class="show-hide" [ngClass]="{'icon-ic_hide_sb' : !competency.isHidden, 'icon-ic_show_sb' : competency.isHidden}"></i>
                                       <p class="show-hide-text"> {{competency.isHidden ? 'Show' :'Hide'}}</p>
                                    </div>

                                    <div class="flex-position collapse-heading  heading-sub" [ngClass]="{'dashed-line-after': (showToggle[$index]===true && competency.subCompetencies.length > 0),
                                       'dark-background' : competency.isHidden}">
                                       <div class="bullet-container no-padding">
                                          <img src="assets/images/mk_cmptnc.png" />
                                       </div>
                                       <div class="flex-align border-width">
                                          <h5 class="light-medium-bold">
                                             <span class="competency-code">{{competency.code}}</span>
                                             <span class="dark-medium title-sub">{{competency.name}}</span>
                                          </h5>
                                          <p class="degree-cc-status"
                                             *ngIf="competency.status.completed === 'Achieved'">
                                             <span class="lorateicon icon-ic_complete-green"></span>
                                             <span class="archieved">{{competency.status.completed}}</span>
                                             <span class="degree-cc-date">
                                                {{formatDate(competency.completedDate)}}</span>
                                          </p>
                                          <p class="degree-cc-status"
                                             *ngIf="competency.status.completed === 'Mastered'">
                                             <span class="lorateicon icon-ic_masterd"></span>
                                             <span class="mastered">{{competency.status.completed}}</span>
                                             <span class="degree-cc-date">
                                                {{formatDate(competency.completedDate)}}</span>
                                          </p>
                                          <p class="degree-cc-status" *ngIf="!competency.status.completed">In Progress
                                          </p>
                                       </div>
                                    </div>
                                 </div>

                                 <!-- sub-competencies -->
                                 <ul class="collapse collapse-block-{{item.code}}-{{$index}}"
                                    *ngIf="competency.subCompetencies.length > 0 || (isreadOnlyView && !competency.isHidden)"
                                    [ngClass]="{'dark-background' : competency.isHidden}">

                                    <li *ngFor="let subcompetency of competency.subCompetencies;">
                                       <div class="flex-position">
                                          <div class="bullet-container no-padding">
                                             <img src="assets/images/mk_subcomp.png" />
                                          </div>
                                          <p class="degree-cc-step">{{subcompetency.text}}</p>
                                       </div>
                                    </li>
                                 </ul>
                              </li>
                           </ng-container>
                        </ul>
                     </li>
                  </ul>
               </div>

               <div id="collapseData-{{item.code}}-{{$index}}" data-toggle="collapse" class="btn-container collapse-button collapsed"
                  (click)="showToggle[$index] = !showToggle[$index]" [attr.data-target]="'.collapse-block-' + item.code + '-' + $index">
                  <button class="btn-open">
                     <!-- <span [ngClass]="showToggle[$index]===true ? 'icon-ic_chev-up': 'icon-ic_chev-down'"></span> -->
                     <span class="icon-ic_chev-up"></span>
                     <span class="icon-ic_chev-down"></span>
                  </button>
               </div>

            </div>
         </ng-container>
      </div>
      </ng-container>
   </div>
</div>