import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var SkillsComponent = /** @class */ (function () {
    function SkillsComponent(actRoute) {
        this.actRoute = actRoute;
        this.title = '';
        this.abc = false;
        this.isreadOnlyView = false;
    }
    SkillsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actRoute.params.subscribe(function (params) {
            if (params) {
                _this.isreadOnlyView = params.accessToken ? true : false;
            }
        });
        this.title = 'PROFESSIONAL SKILLS';
        this.customOptions = {
            loop: false,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: false,
            dots: false,
            navSpeed: 700,
            navText: ['<img src="assets/images/ic_prev.png" class="prev-img">',
                '<img src="assets/images/ic_next.png" class="next-img">'],
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 2
                },
                767: {
                    items: 3
                },
                940: {
                    items: 3
                },
                1240: {
                    items: 4
                }
            },
            nav: true
        };
    };
    SkillsComponent.prototype.onHover = function () {
        document.getElementsByClassName('owl-next')[0].classList.add('owl-next-nav');
        document.getElementsByClassName('owl-prev')[0].classList.add('owl-prev-nav');
    };
    SkillsComponent.prototype.onHoverOut = function () {
        document.getElementsByClassName('owl-next')[0].classList.remove('owl-next-nav');
        document.getElementsByClassName('owl-prev')[0].classList.remove('owl-prev-nav');
    };
    SkillsComponent.prototype.toggleSkills = function (skill) {
        skill.isHidden = !skill.isHidden;
    };
    return SkillsComponent;
}());
export { SkillsComponent };
