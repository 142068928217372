/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-owl-carousel-o";
import * as i3 from "../../../../../node_modules/ngx-owl-carousel-o/ngx-owl-carousel-o.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "./skills.component";
var styles_SkillsComponent = [];
var RenderType_SkillsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SkillsComponent, data: {} });
export { RenderType_SkillsComponent as RenderType_SkillsComponent };
function View_SkillsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "div", [["class", "skill-outer"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "hidden-skill": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "i", [["class", "show-hide"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSkills(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "icon-ic_show_sb": 0, "icon-ic_hide_sb": 1 }), (_l()(), i0.ɵeld(7, 0, null, null, 14, "div", [["class", "skill-wrapper "]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "skill-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["src", "assets/images/ic_badge.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "badge-desc"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "skill-tooltip"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "span", [["class", "lorateicon icon-i_info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 7, "div", [["class", "skilltext"]], null, [[null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.onHover() !== false);
        ad = (pd_0 && ad);
    } if (("mouseout" === en)) {
        var pd_1 = (_co.onHoverOut() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["class", "flex-position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [["class", "skill-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 0, "img", [["src", "assets/images/ic_badge.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "span", [["class", "badge-desc"]], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["", ""])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "p", [["class", "skill-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "skill-outer"; var currVal_1 = _ck(_v, 3, 0, _v.parent.parent.context.$implicit.isHidden); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "show-hide"; var currVal_4 = _ck(_v, 6, 0, _v.parent.parent.context.$implicit.isHidden, !_v.parent.parent.context.$implicit.isHidden); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_2 = i0.ɵinlineInterpolate(1, "toggleSkill-", _v.parent.parent.context.$implicit.id, ""); _ck(_v, 4, 0, currVal_2); var currVal_5 = _v.parent.parent.context.$implicit.name; _ck(_v, 11, 0, currVal_5); var currVal_6 = _v.parent.parent.context.$implicit.name; _ck(_v, 19, 0, currVal_6); var currVal_7 = _v.parent.parent.context.$implicit.description; _ck(_v, 21, 0, currVal_7); }); }
function View_SkillsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SkillsComponent_3)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isreadOnlyView || (_co.isreadOnlyView && !_v.parent.context.$implicit.isHidden)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SkillsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 1, null, View_SkillsComponent_2)), i0.ɵdid(1, 16384, [[1, 4]], 0, i2.CarouselSlideDirective, [i0.TemplateRef], null, null)], null, null); }
export function View_SkillsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "skill-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h6", [["class", "skill-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 11, "owl-carousel-o", [], null, [["document", "visibilitychange"]], function (_v, en, $event) { var ad = true; if (("document:visibilitychange" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onVisibilityChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CarouselComponent_0, i3.RenderType_CarouselComponent)), i0.ɵprd(512, null, i2.ɵb, i2.ɵb, [i2.ɵc]), i0.ɵprd(131584, null, i2.ɵa, i2.ɵa, [i2.ɵb]), i0.ɵprd(131584, null, i2.ɵd, i2.ɵd, [i2.ɵb, i2.ɵe, i2.ɵl]), i0.ɵprd(131584, null, i2.ɵs, i2.ɵs, [i2.ɵb]), i0.ɵprd(131584, null, i2.ɵt, i2.ɵt, [i2.ɵb]), i0.ɵprd(131584, null, i2.ɵu, i2.ɵu, [i2.ɵb]), i0.ɵprd(131584, null, i2.ɵv, i2.ɵv, [i2.ɵb, [2, i4.ActivatedRoute], [2, i4.Router]]), i0.ɵdid(11, 3391488, null, 1, i2.CarouselComponent, [i0.ElementRef, i2.ɵw, i2.ɵb, i2.ɵa, i2.ɵd, i2.ɵs, i2.ɵt, i2.ɵu, i2.ɵv, i2.ɵc, i2.ɵl], { options: [0, "options"] }, null), i0.ɵqud(603979776, 1, { slides: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SkillsComponent_1)), i0.ɵdid(14, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customOptions; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.skills; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_SkillsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-skills", [], null, null, null, View_SkillsComponent_0, RenderType_SkillsComponent)), i0.ɵdid(1, 114688, null, 0, i5.SkillsComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkillsComponentNgFactory = i0.ɵccf("app-skills", i5.SkillsComponent, View_SkillsComponent_Host_0, { skills: "skills" }, {}, []);
export { SkillsComponentNgFactory as SkillsComponentNgFactory };
