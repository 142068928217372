import { Component, OnInit, Input, ɵConsole } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html'
})
export class SkillsComponent implements OnInit {

  @Input() skills: any;
  public title = '';
  public customOptions: any;
  public abc = false;
  public isreadOnlyView = false;
  
  constructor(
    private actRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.actRoute.params.subscribe(params => {
      if (params) {
        this.isreadOnlyView = params.accessToken ? true : false;
      }
    });
    this.title = 'PROFESSIONAL SKILLS';
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      navText: ['<img src="assets/images/ic_prev.png" class="prev-img">',
        '<img src="assets/images/ic_next.png" class="next-img">'],
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 2
        },
        767: {
          items: 3
        },
        940: {
          items: 3
        },
        1240: {
          items: 4
        }
      },
      nav: true
    };
  }

  onHover() {
    document.getElementsByClassName('owl-next')[0].classList.add('owl-next-nav');
    document.getElementsByClassName('owl-prev')[0].classList.add('owl-prev-nav');
  }
  onHoverOut() {
    document.getElementsByClassName('owl-next')[0].classList.remove('owl-next-nav');
    document.getElementsByClassName('owl-prev')[0].classList.remove('owl-prev-nav');
  }

  public toggleSkills(skill){
    skill.isHidden = !skill.isHidden;
  }

}
