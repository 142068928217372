import { OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/common/authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.stg';
import { Constants } from '../../../util/constants/constants';
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(auth, router, constants) {
        this.auth = auth;
        this.router = router;
        this.constants = constants;
    }
    ErrorComponent.prototype.ngOnInit = function () {
    };
    /**
     * @description close the current tab since the user
     * doesnt have any compentencies completed.
     */
    ErrorComponent.prototype.closeTab = function () {
        window.location.href = environment.waldenTempoUrl;
    };
    ErrorComponent.prototype.reloadPage = function () {
        location.reload();
    };
    return ErrorComponent;
}());
export { ErrorComponent };
