/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../common/loader/loader.component.ngfactory";
import * as i2 from "../common/loader/loader.component";
import * as i3 from "../common/skills/skills.component.ngfactory";
import * as i4 from "../common/skills/skills.component";
import * as i5 from "@angular/router";
import * as i6 from "../common/sidebar/sidebar.component.ngfactory";
import * as i7 from "../common/sidebar/sidebar.component";
import * as i8 from "../../services/common/authentication.service";
import * as i9 from "../../services/clr-viewer/clr-viewer.service";
import * as i10 from "../../util/constants/constants";
import * as i11 from "../../services/common/util.service";
import * as i12 from "ngx-toastr";
import * as i13 from "@angular/fire/firestore";
import * as i14 from "@angular/common";
import * as i15 from "../common/program/program.component.ngfactory";
import * as i16 from "../common/program/program.component";
import * as i17 from "../common/error/error.component.ngfactory";
import * as i18 from "../common/error/error.component";
import * as i19 from "./clr-share.component";
var styles_ClrShareComponent = [];
var RenderType_ClrShareComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClrShareComponent, data: {} });
export { RenderType_ClrShareComponent as RenderType_ClrShareComponent };
function View_ClrShareComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ClrShareComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-skills", [], null, null, null, i3.View_SkillsComponent_0, i3.RenderType_SkillsComponent)), i0.ɵdid(1, 114688, null, 0, i4.SkillsComponent, [i5.ActivatedRoute], { skills: [0, "skills"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.academics.skills; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ClrShareComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["id", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "outer-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "left-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-sidebar", [], null, null, null, i6.View_SidebarComponent_0, i6.RenderType_SidebarComponent)), i0.ɵdid(5, 114688, null, 0, i7.SidebarComponent, [i8.AuthenticationService, i5.Router, i9.ClrViewerService, i10.Constants, i11.UtilService, i12.ToastrService, i5.ActivatedRoute, i13.AngularFirestore], { issuer: [0, "issuer"], records: [1, "records"], user: [2, "user"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "right-section detail-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "detailed-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrShareComponent_3)), i0.ɵdid(9, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-program", [], null, null, null, i15.View_ProgramComponent_0, i15.RenderType_ProgramComponent)), i0.ɵdid(11, 114688, null, 0, i16.ProgramComponent, [i11.UtilService, i5.ActivatedRoute], { academics: [0, "academics"], issuer: [1, "issuer"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issuers; var currVal_1 = _co.records; var currVal_2 = _co.currentUser; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.academics.skills.length > 0); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.academics; var currVal_5 = _co.issuers; _ck(_v, 11, 0, currVal_4, currVal_5); }, null); }
function View_ClrShareComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, i17.View_ErrorComponent_0, i17.RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i18.ErrorComponent, [i8.AuthenticationService, i5.Router, i10.Constants], { message: [0, "message"], errorMessage: [1, "errorMessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.noRecords; var currVal_1 = _co.errors.text; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ClrShareComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrShareComponent_1)), i0.ɵdid(1, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "share-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrShareComponent_2)), i0.ɵdid(4, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrShareComponent_4)), i0.ɵdid(6, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.issuers && !_co.isLoading) && _co.showAcademicsSection); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.issuers || !_co.showAcademicsSection); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ClrShareComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-clr-share", [], null, null, null, View_ClrShareComponent_0, RenderType_ClrShareComponent)), i0.ɵdid(1, 114688, null, 0, i19.ClrShareComponent, [i9.ClrViewerService, i10.Constants, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClrShareComponentNgFactory = i0.ɵccf("app-clr-share", i19.ClrShareComponent, View_ClrShareComponent_Host_0, {}, {}, []);
export { ClrShareComponentNgFactory as ClrShareComponentNgFactory };
