import { Http } from '@angular/http';
import { Headers } from '@angular/http';
var ApicallService = /** @class */ (function () {
    function ApicallService(http) {
        this.http = http;
    }
    /**
     * @desc generate the request headers for the token authentication
     * @param flag
     * Note: Here we add the session token and LTPA token
     */
    ApicallService.prototype.generateHeaders = function () {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('idToken'));
        return headers;
    };
    /**
     * @desc Http service call for GET method
     * @param {string} apiUrl api url
     * @param {function} successCallback success callback
     * @param {function} failureCallback failure callback
     **/
    ApicallService.prototype.doGetAPIAction = function (apiUrl, successCallback, failureCallback) {
        var _headers = this.generateHeaders();
        var options = { headers: _headers };
        this.http
            .get(apiUrl, options)
            .subscribe(function (response) {
            successCallback(response);
        }, function (error) {
            failureCallback(error);
        });
    };
    /**
     * @desc Http service call for POST method
     * @param {string} apiUrl api url
     * @param {Object} data the data to be posted to the api
     * @param {function} successCallback success callback
     * @param {function} failureCallback failure callback
     **/
    ApicallService.prototype.doPostAPIAction = function (apiUrl, data, successCallback, failureCallback) {
        var _headers = this.generateHeaders();
        var options = { headers: _headers };
        this.http
            .post(apiUrl, data, options)
            .subscribe(function (response) {
            successCallback(response);
        }, function (error) {
            failureCallback(error);
        });
    };
    /**
     * @desc Http service call for PUT method
     * @param {string} apiUrl api url
     * @param {Object} data the data to be posted to the api
     * @param {function} successCallback success callback
     * @param {function} failureCallback failure callback
     **/
    ApicallService.prototype.doPutAPIAction = function (apiUrl, data, successCallback, failureCallback) {
        var _headers = this.generateHeaders();
        var options = { headers: _headers };
        this.http
            .put(apiUrl, data, options)
            .subscribe(function (response) {
            successCallback(response);
        }, function (error) {
            failureCallback(error);
        });
    };
    /**
     * @desc Http service call for DELETE method
     * @param {string} apiUrl api url
     * @param {function} successCallback success callback
     * @param {function} failureCallback failure callback
     **/
    ApicallService.prototype.doDeleteAPIAction = function (apiUrl, successCallback, failureCallback) {
        var _headers = this.generateHeaders();
        var options = { headers: _headers };
        this.http
            .delete(apiUrl, options)
            .subscribe(function (response) {
            successCallback(response);
        }, function (error) {
            failureCallback(error);
        });
    };
    return ApicallService;
}());
export { ApicallService };
