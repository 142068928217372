import { OnInit } from '@angular/core';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { DataMappingService } from '../../services/clr-viewer/data-mapping.service';
import * as firebase from 'firebase/app';
import { Constants } from '../../util/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/common/util.service';
var ClrBuilderComponent = /** @class */ (function () {
    function ClrBuilderComponent(viewerService, dataMappingService, constants, toastr, router, util) {
        this.viewerService = viewerService;
        this.dataMappingService = dataMappingService;
        this.constants = constants;
        this.toastr = toastr;
        this.router = router;
        this.util = util;
        this.errors = {
            noRecords: false,
            text: ''
        };
        this.isLoading = true;
        this.savingSnapshot = false;
        this.autoSyncError = false;
        this.showAcademicsSection = false;
        this.editVersionName = false;
        this.version = {
            name: '',
            title: ''
        };
    }
    /**
     * @description The component initialization
     * Note: will check if the user is logged in or not.
     * If yes, the details of the current user is fetched from the firebase auth
     * and is saved to the currentUser object.
     */
    ClrBuilderComponent.prototype.ngOnInit = function () {
        if (JSON.parse(sessionStorage.getItem('version'))) {
            this.version = JSON.parse(sessionStorage.getItem('version'));
        }
        var _self = this;
        localStorage.removeItem('backToCLR');
        this.errors.noRecords = false;
        this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                _self.currentUser = user;
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
                    sessionStorage.setItem('idToken', idToken);
                    _self.getDataForClrViewer(_self.currentUser.uid);
                });
            }
        });
    };
    /**
     * @description method to sync the data from salesforce to firestore.
     * Note: Once the user is logged in,
     * this will be called automatically or else he/she can do it manually too.
     */
    ClrBuilderComponent.prototype.syncData = function () {
        var _this = this;
        this.viewerService.syncData(this.constants.VALUES.MAPPING_ID, this.currentUser.email, function (response) {
        }, function (error) {
            _this.toastr.error(_this.constants.VALUES.ERRORS.SYNC.MESSAGE, _this.constants.VALUES.ERRORS.SYNC.TYPE);
            _this.autoSyncError = true;
        });
    };
    /**
     * @description Subscribing to the data for binding the details
     * in the CLR Viewer.
     * Note: Once any update happens to the data in the firebase db,
     * the UI will be refreshed automatically with the new changes.
     */
    ClrBuilderComponent.prototype.getDataForClrViewer = function (currentUser) {
        var _this = this;
        this.viewerService.getDataForCLRSnapShot(currentUser, this.version.title)
            .subscribe(function (result) {
            _this.isLoading = result ? false : true;
            _this.issuers = result;
            _this.records = [];
            if (_this.issuers && (_this.issuers.records && _this.issuers.records.length > 0)) {
                _this.issuers.person.ex_userInputUrl === 'undefined' ? _this.issuers.person.ex_userInputUrl = '' : _this.issuers.person.ex_userInputUrl;
                if (_this.issuers.transcriptEntities && !_this.issuers.transcriptEntities.academics) {
                    _this.issuers.transcriptEntities.academics = _this.dataMappingService.mapAcademicsData(_this.issuers);
                }
                _this.academics = _this.issuers.transcriptEntities.academics;
                _this.showAcademicsSection = _this.academics.isActive ? true : false;
                _this.errors.noRecords = true;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_RECORD;
            }
            else if (!_this.issuers || _this.issuers.length === 0) {
                _this.router.navigate(['/clr-viewer']);
                _this.errors.noRecords = false;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_DATA;
                _this.showAcademicsSection = false;
                _this.isLoading = false;
            }
        });
    };
    /**
     * @description redirect to viewwer on clicking home icon in builder.
     */
    ClrBuilderComponent.prototype.redirctToCLRViewwer = function () {
        sessionStorage.setItem('fromBuilder', JSON.stringify(true));
        this.router.navigate(['/clr-viewer']);
    };
    /**
     * @description Copy the share url to clipboard
     * Note : User can paste this url to the browser and it will load the page.
     */
    ClrBuilderComponent.prototype.copyShareUrlToClipboard = function (newTab) {
        var accessToken = this.generateUniqueAccessToken();
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.constants.VALUES.CLR_SHARE.URL + accessToken;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.createNewClrShareDoc(accessToken, newTab);
    };
    /**
     * @description generate unique accesstoken for share url
     */
    ClrBuilderComponent.prototype.generateUniqueAccessToken = function () {
        var tokenString = this.issuers.person.studentId + "-" +
            (Date.now() + "-") +
            (this.issuers.issuer.name + "-") +
            ((this.issuers.person.givenName).toLocaleUpperCase() + "-") +
            ("" + this.issuers.person.familyName.toLocaleUpperCase());
        var uniqueToken = this.util.getSaltedHash(tokenString);
        return uniqueToken;
    };
    /**
     * @description save generatedtoken along with the user details in firestore
     */
    ClrBuilderComponent.prototype.createNewClrShareDoc = function (uniqueToken, newTab) {
        var _this = this;
        var doc = {
            createdate: new Date(),
            expiredate: this.issuers.snapshotExpiry,
            snapshot: this.issuers.snapshotTitle,
            uid: this.currentUser.uid
        };
        this.viewerService.setDataForCLRShare(this.currentUser.uid, uniqueToken, doc)
            .then(function (result) {
            if (newTab) {
                window.open(_this.constants.VALUES.CLR_SHARE.URL + uniqueToken);
            }
            else {
                _this.toastr.success("<a>" + _this.constants.VALUES.CLR_SHARE.URL + uniqueToken + "</a>", _this.constants.VALUES.SUCCESS.COPY.MESSAGE);
            }
        });
    };
    /**
     * @description save the snapshot version from builder
     */
    ClrBuilderComponent.prototype.saveSnapshotVersion = function () {
        var _this = this;
        this.savingSnapshot = true;
        this.viewerService.setDataForCLRViewer(this.currentUser.uid, this.issuers, this.issuers.snapshotTitle)
            .then(function (result) {
            _this.savingSnapshot = false;
        });
    };
    ClrBuilderComponent.prototype.saveVersionName = function () {
        var _this = this;
        this.savingSnapshot = true;
        this.issuers.snapshotName = this.version.name;
        this.viewerService.setDataForCLRViewer(this.currentUser.uid, this.issuers, this.issuers.snapshotTitle)
            .then(function (result) {
            _this.savingSnapshot = false;
            _this.editVersionName = false;
        });
    };
    /**
     * @description refresh snapshot data from master.
     */
    ClrBuilderComponent.prototype.refreshSnapShotData = function (refreshSnapshot) {
        var _this = this;
        var _masterData;
        this.viewerService.getDataForCLRSnapShot(this.currentUser.uid, this.constants.VALUES.FIRESTORE_DB_CONSTANTS.MASTER)
            .subscribe(function (result) {
            if (result) {
                if (refreshSnapshot) {
                    var _issuerData = {
                        snapshotExpiry: _this.issuers.snapshotExpiry,
                        snapshotName: _this.issuers.snapshotName,
                        snapshotTitle: _this.issuers.snapshotTitle,
                        hideDOB: _this.issuers.person.hideDOB ? _this.issuers.person.hideDOB : false,
                        hideEmail: _this.issuers.person.hideEmail ? _this.issuers.person.hideEmail : false,
                        hidePhone: _this.issuers.person.hidePhone ? _this.issuers.person.hidePhone : false,
                        hideStudentId: _this.issuers.person.hideStudentId ? _this.issuers.person.hideStudentId : false,
                        hideUrl: _this.issuers.person.hideUrl ? _this.issuers.person.hideUrl : false,
                    };
                    _this.issuers = result;
                    _this.issuers.lastTriggeredAt = _this.util.setRefreshedDate(new Date());
                    _this.issuers.snapshotExpiry = _this.util.setExpiryDate(new Date());
                    _this.issuers.snapshotName = _issuerData.snapshotName;
                    _this.issuers.snapshotTitle = _issuerData.snapshotTitle;
                    _this.issuers.person.hideDOB = _issuerData.hideDOB;
                    _this.issuers.person.hideEmail = _issuerData.hideEmail;
                    _this.issuers.person.hidePhone = _issuerData.hidePhone;
                    _this.issuers.person.hideStudentId = _issuerData.hideStudentId;
                    _this.issuers.person.hideUrl = _issuerData.hideUrl;
                    _this.issuers.transcriptEntities.academics = _this.academics;
                    _this.saveSnapshotVersion();
                    refreshSnapshot = false;
                }
            }
        });
    };
    return ClrBuilderComponent;
}());
export { ClrBuilderComponent };
