/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../common/loader/loader.component.ngfactory";
import * as i2 from "../common/loader/loader.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "../common/skills/skills.component.ngfactory";
import * as i6 from "../common/skills/skills.component";
import * as i7 from "@angular/router";
import * as i8 from "../common/sidebar/sidebar.component.ngfactory";
import * as i9 from "../common/sidebar/sidebar.component";
import * as i10 from "../../services/common/authentication.service";
import * as i11 from "../../services/clr-viewer/clr-viewer.service";
import * as i12 from "../../util/constants/constants";
import * as i13 from "../../services/common/util.service";
import * as i14 from "ngx-toastr";
import * as i15 from "@angular/fire/firestore";
import * as i16 from "../common/program/program.component.ngfactory";
import * as i17 from "../common/program/program.component";
import * as i18 from "../common/error/error.component.ngfactory";
import * as i19 from "../common/error/error.component";
import * as i20 from "./clr-builder.component";
import * as i21 from "../../services/clr-viewer/data-mapping.service";
var styles_ClrBuilderComponent = [];
var RenderType_ClrBuilderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClrBuilderComponent, data: {} });
export { RenderType_ClrBuilderComponent as RenderType_ClrBuilderComponent };
function View_ClrBuilderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ClrBuilderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "version"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), (_co.version && _co.version.name))); _ck(_v, 1, 0, currVal_0); }); }
function View_ClrBuilderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-ic_edit-title edit-button"], ["id", "editBuilder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.editVersionName = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version.name; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ClrBuilderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "input", [["class", "version-name"], ["id", "snapshotVersionName"], ["maxlength", "30"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.version.name = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i4.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 540672, null, 0, i4.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.MaxLengthValidator]), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "img", [["class", "action-link"], ["id", "saveVersionName"], ["src", "./assets/images/ic_builderSave.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveVersionName() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "30"; _ck(_v, 3, 0, currVal_8); var currVal_9 = _co.version.name; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 3).maxlength ? i0.ɵnov(_v, 3).maxlength : null); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_ClrBuilderComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-skills", [], null, null, null, i5.View_SkillsComponent_0, i5.RenderType_SkillsComponent)), i0.ɵdid(1, 114688, null, 0, i6.SkillsComponent, [i7.ActivatedRoute], { skills: [0, "skills"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.academics.skills; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ClrBuilderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["id", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "outer-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "left-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-sidebar", [], null, null, null, i8.View_SidebarComponent_0, i8.RenderType_SidebarComponent)), i0.ɵdid(5, 114688, null, 0, i9.SidebarComponent, [i10.AuthenticationService, i7.Router, i11.ClrViewerService, i12.Constants, i13.UtilService, i14.ToastrService, i7.ActivatedRoute, i15.AngularFirestore], { issuer: [0, "issuer"], records: [1, "records"], user: [2, "user"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "right-section detail-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "detailed-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_7)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-program", [], null, null, null, i16.View_ProgramComponent_0, i16.RenderType_ProgramComponent)), i0.ɵdid(11, 114688, null, 0, i17.ProgramComponent, [i13.UtilService, i7.ActivatedRoute], { academics: [0, "academics"], issuer: [1, "issuer"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.issuers; var currVal_1 = _co.records; var currVal_2 = _co.currentUser; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.academics.skills.length > 0); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.academics; var currVal_5 = _co.issuers; _ck(_v, 11, 0, currVal_4, currVal_5); }, null); }
function View_ClrBuilderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 48, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 26, "div", [["class", "topnav flex-align"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "topnav-left flex-position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-ic_home-builder home-button"], ["id", "goToClrViewer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirctToCLRViewwer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_5)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 19, "div", [["class", "topnav-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "button", [["class", "builder-button preview"], ["id", "previewBuilder"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyShareUrlToClipboard(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "icon-ic_preview-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Preview"])), (_l()(), i0.ɵeld(14, 0, null, null, 4, "button", [["class", "builder-button copy"], ["id", "copyUrlBuilder"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyShareUrlToClipboard(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "icon-ic_copy-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Share Version"])), (_l()(), i0.ɵeld(19, 0, null, null, 4, "button", [["class", "builder-button sync"], ["id", "refreshVersionBuilder"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshSnapShotData(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "i", [["class", "icon-ic_sync-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Refresh Academic data"])), (_l()(), i0.ɵeld(24, 0, null, null, 3, "button", [["class", "builder-button save"], ["id", "saveVersionBuilder"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveSnapshotVersion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 0, "i", [["class", "icon-ic_builderSave builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["class", "builder-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_6)), i0.ɵdid(30, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(31, 0, null, null, 17, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 16, "div", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 2, "button", [["class", "builder-button btn-home"], ["id", "homeBuilderMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirctToCLRViewwer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(34, 0, null, null, 0, "i", [["class", "icon-ic_home-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Home "])), (_l()(), i0.ɵeld(36, 0, null, null, 2, "button", [["class", "builder-button btn-preview"], ["id", "previewBuilderMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyShareUrlToClipboard(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 0, "i", [["class", "icon-ic_preview-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Preview "])), (_l()(), i0.ɵeld(39, 0, null, null, 2, "button", [["class", "builder-button btn-copy"], ["id", "copyUrlBuilderMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyShareUrlToClipboard(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(40, 0, null, null, 0, "i", [["class", "icon-ic_copy-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Share"])), (_l()(), i0.ɵeld(42, 0, null, null, 2, "button", [["class", "builder-button btn-sync"], ["id", "refreshVersionBuilderMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshSnapShotData(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 0, "i", [["class", "icon-ic_sync-builder builder-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Refresh"])), (_l()(), i0.ɵeld(45, 0, null, null, 3, "button", [["class", "builder-button btn-sync"], ["id", "saveVersionBuilderMobile"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveSnapshotVersion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(46, 0, null, null, 0, "img", [["src", "./assets/images/ic_builderSave.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(47, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editVersionName; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.editVersionName; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.issuers && !_co.isLoading) && _co.academics.isActive); _ck(_v, 30, 0, currVal_2); }, null); }
function View_ClrBuilderComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, i18.View_ErrorComponent_0, i18.RenderType_ErrorComponent)), i0.ɵdid(1, 114688, null, 0, i19.ErrorComponent, [i10.AuthenticationService, i7.Router, i12.Constants], { message: [0, "message"], errorMessage: [1, "errorMessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.noRecords; var currVal_1 = _co.errors.text; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ClrBuilderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.UpperCasePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_2)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ClrBuilderComponent_8)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isLoading || _co.savingSnapshot); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.issuers && !_co.isLoading) && _co.academics.isActive); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.issuers || !_co.academics.isActive); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ClrBuilderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-clr-builder", [], null, null, null, View_ClrBuilderComponent_0, RenderType_ClrBuilderComponent)), i0.ɵdid(1, 114688, null, 0, i20.ClrBuilderComponent, [i11.ClrViewerService, i21.DataMappingService, i12.Constants, i14.ToastrService, i7.Router, i13.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClrBuilderComponentNgFactory = i0.ɵccf("app-clr-builder", i20.ClrBuilderComponent, View_ClrBuilderComponent_Host_0, {}, {}, []);
export { ClrBuilderComponentNgFactory as ClrBuilderComponentNgFactory };
