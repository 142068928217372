import { ApicallService } from '../../util/apicall.service';
import { Constants } from '../../util/constants/constants';
import { APIConstants } from '../../util/constants/apiconstants';
import { Timezone } from '../../util/constants/timezone';
import { sha256 } from 'js-sha256';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../util/apicall.service";
import * as i2 from "../../util/constants/constants";
import * as i3 from "../../util/constants/apiconstants";
import * as i4 from "../../util/constants/timezone";
var UtilService = /** @class */ (function () {
    function UtilService(apicallService, constants, apiConstants, tz) {
        this.apicallService = apicallService;
        this.constants = constants;
        this.apiConstants = apiConstants;
        this.tz = tz;
        this.loader = new BehaviorSubject(true);
    }
    /**
     * @description toggle loader for components
     */
    UtilService.prototype.showLoader = function (value) {
        this.loader.next(value);
    };
    /**
     * @desc method to format the date.
     * @param dateString date string to be formatted.
     */
    UtilService.prototype.getFormattedDate = function (dateString) {
        if (dateString) {
            var _date = new Date(dateString).toDateString().split(' ');
            var _time = new Date(dateString).toTimeString().split(' ')[0].split(':');
            var _am = parseInt(_time[0]) >= 12 ? ' PM' : ' AM';
            var _tap = parseInt(_time[0]) > 12 ? (parseInt(_time[0]) - 12).toString() : _time[0];
            var _tz = this.getTimeZone(/\((.*)\)/.exec(new Date(dateString).toString())[1]);
            var _tm = _tap.toString().length === 1 ? '0' + _tap.toString() : _tap.toString();
            var _ts = _time[1].toString().length === 1 ? '0' + _time[1].toString() : _time[1].toString();
            return {
                date: this.constants.VALUES.MONTHS[_date[1]] + ' ' + _date[2] + ', ' + _date[3],
                time: _tm + ':' + _ts + _am,
                zone: _tz
            };
        }
    };
    /**
     * @description gettimezone abbrevation from expanded string.
     * @param val expanded string whse abbr is to be retrieved.
     */
    UtilService.prototype.getTimeZone = function (val) {
        var _zone = '';
        this.tz.zones.forEach(function (item) {
            if (item.value === val) {
                _zone = item.abbr;
            }
        });
        return _zone;
    };
    /**
     * @description hash the input string using SHA256and add a salt.
     * @param hashString string to be hashed and added salt
     */
    UtilService.prototype.getSaltedHash = function (hashString) {
        var hash = sha256(hashString);
        var salt = environment.hash.isFixedSalt ? this.constants.VALUES.CLR_SHARE.SALT : this.generateSalt();
        var uniqueToken = hash + salt;
        return uniqueToken;
    };
    /**
     * @description generate a random salt for creating access token for share CLR
     */
    UtilService.prototype.generateSalt = function () {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
    /**
     * @description format the date irrespective of timezones.
     * @param date date string to be formatted.
     */
    UtilService.prototype.formatDateString = function (date) {
        if (date) {
            var _day = date.split('-')[2].toString().length === 1 ? ('0' + date.split('-')[2].toString()) : date.split('-')[2].toString();
            var _month = date.split('-')[1].toString().length === 1 ? ('0' + date.split('-')[1].toString()) : date.split('-')[1].toString();
            var _year = date.split('-')[0].toString();
            return this.constants.VALUES.MONTHS_IN_NUMBERS[_month] + ' ' + _day + ', ' + _year;
        }
        else {
            return '';
        }
    };
    /**
     * @description Set expiry date for the share versions created.
     */
    UtilService.prototype.setExpiryDate = function (date) {
        if (date) {
            var _date = new Date(date);
            var _newDate = _date.setDate(_date.getDate() + environment.CLRShare.expiryDate);
            return _newDate;
        }
    };
    /**
     * @description Set expiry date for the share versions created.
     */
    UtilService.prototype.setRefreshedDate = function (date) {
        if (date) {
            var _date = new Date(date);
            var _newDate = _date.setDate(_date.getDate());
            return _newDate;
        }
    };
    UtilService.ngInjectableDef = i0.defineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.inject(i1.ApicallService), i0.inject(i2.Constants), i0.inject(i3.APIConstants), i0.inject(i4.Timezone)); }, token: UtilService, providedIn: "root" });
    return UtilService;
}());
export { UtilService };
