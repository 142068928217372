/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../common/loader/loader.component.ngfactory";
import * as i2 from "../common/loader/loader.component";
import * as i3 from "@angular/common";
import * as i4 from "./authentication.component";
import * as i5 from "../../services/common/authentication.service";
import * as i6 from "@angular/router";
var styles_AuthenticationComponent = [];
var RenderType_AuthenticationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthenticationComponent, data: {} });
export { RenderType_AuthenticationComponent as RenderType_AuthenticationComponent };
function View_AuthenticationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AuthenticationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "logout-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "logout-bckgrnd-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "logout-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "logo-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/logo_white.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "logout-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You have exited from your record. Click on \"Go Back\" to visit your record again. "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "button", [["class", "btn-goBack"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectToCLRViewer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["go back"]))], null, null); }
export function View_AuthenticationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AuthenticationComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AuthenticationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthenticationComponent_0, RenderType_AuthenticationComponent)), i0.ɵdid(1, 114688, null, 0, i4.AuthenticationComponent, [i5.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationComponentNgFactory = i0.ɵccf("app-auth", i4.AuthenticationComponent, View_AuthenticationComponent_Host_0, {}, {}, []);
export { AuthenticationComponentNgFactory as AuthenticationComponentNgFactory };
