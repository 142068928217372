<app-loader *ngIf="isLoading || savingSnapshot"></app-loader>

<ng-container *ngIf="issuers && !isLoading && academics.isActive">
  <div class="topnav flex-align">

    <div class="topnav-left flex-position">
      <i id="goToClrViewer" class="icon-ic_home-builder home-button" (click)="redirctToCLRViewwer()"></i>
      <ng-container *ngIf="!editVersionName">
          <span class="version" *ngIf="version.name">{{version && version.name | uppercase}}</span>
          <i id="editBuilder" class="icon-ic_edit-title edit-button" (click)="editVersionName = true;"></i>
      </ng-container>
      <ng-container *ngIf="editVersionName">
          <input id="snapshotVersionName" class="version-name" type="text" [(ngModel)]="version.name" maxlength="30">
          <img id="saveVersionName" class="action-link" src="./assets/images/ic_builderSave.svg" (click)="saveVersionName()"/>
      </ng-container>
    </div>

    <div class="topnav-right">
      <button id="previewBuilder" type="button" class="builder-button preview" (click)="copyShareUrlToClipboard(true)">
        <span>
          <i class="icon-ic_preview-builder builder-icon"></i>
          <span class="text">Preview</span>
        </span>
      </button>
      <button id="copyUrlBuilder" type="button" class="builder-button copy" (click)="copyShareUrlToClipboard(false)">
        <span>
          <i class="icon-ic_copy-builder builder-icon"></i>
          <span class="text">Share Version</span>
        </span>
      </button>
      <button id="refreshVersionBuilder" type="button" class="builder-button sync" (click)="refreshSnapShotData(true)">
        <span>
          <i class="icon-ic_sync-builder builder-icon"></i>
          <span class="text">Refresh Academic data</span>
        </span>
      </button>
      <button id="saveVersionBuilder" type="button" class="builder-button save" (click)="saveSnapshotVersion()">
         <i class="icon-ic_builderSave builder-icon"></i>
          <span class="text">Save</span>
      </button>
    </div>
  </div>


  <div class="builder-container">
    <div id="wrapper" *ngIf="issuers && !isLoading && academics.isActive">
      <div class="container-fluid">
        <div class="outer-wrapper">
          <div class="left-section">
            <app-sidebar [user]="currentUser" [issuer]="issuers" [records]="records"></app-sidebar>
          </div>
          <div class="right-section detail-container">
            <div class="detailed-section">
              <app-skills *ngIf="academics.skills.length > 0" [skills]="academics.skills"></app-skills>
              <app-program [academics]="academics" [issuer]="issuers"></app-program>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="footer-container">
      <button id="homeBuilderMobile" type="button" class="builder-button btn-home" (click)="redirctToCLRViewwer()">
        <i class="icon-ic_home-builder builder-icon"></i>
        Home </button>
      <button id="previewBuilderMobile" type="button" class="builder-button btn-preview" (click)="copyShareUrlToClipboard(true)">
        <i class="icon-ic_preview-builder builder-icon"></i>
        Preview </button>
      <button id="copyUrlBuilderMobile" type="button" class="builder-button btn-copy" (click)="copyShareUrlToClipboard(false)">
        <i class="icon-ic_copy-builder builder-icon"></i>
        Share</button>
      <button id="refreshVersionBuilderMobile" type="button" class="builder-button btn-sync" (click)="refreshSnapShotData(true)">
        <i class="icon-ic_sync-builder builder-icon"></i>
        Refresh</button>
      <button id="saveVersionBuilderMobile" type="button" class="builder-button btn-sync" (click)="saveSnapshotVersion()">
          <img src="./assets/images/ic_builderSave.svg" />
          <p>Save</p>
          </button>
    </div>
  </div>
</ng-container>
<app-error *ngIf="!issuers || !academics.isActive" [message]="errors.noRecords" [errorMessage]="errors.text"></app-error>