<div class="skill-section">
    <h6 class="skill-title">
        {{ title }}
    </h6>
    <owl-carousel-o [options]="customOptions">
        
        <ng-template carouselSlide *ngFor="let skill of skills">
            <ng-container *ngIf="!isreadOnlyView || (isreadOnlyView && !skill.isHidden)">
                    <div class="skill-outer" [ngClass]="{'hidden-skill' : skill.isHidden}">
                            <i id="toggleSkill-{{skill.id}}"  class="show-hide" [ngClass]="{'icon-ic_show_sb':skill.isHidden,'icon-ic_hide_sb':!skill.isHidden}"
                            (click)="toggleSkills(skill)"></i>
                        <div class="skill-wrapper ">
                            <div class="skill-logo">
                                <img src="assets/images/ic_badge.png" />
                            </div>
                            <span class="badge-desc">{{skill.name}}</span>
                            <div class="skill-tooltip">
                                <span class="lorateicon icon-i_info"></span>
                                <div class="skilltext" (mouseover)='onHover()' (mouseout)='onHoverOut()'>
                                    <div class="flex-position">
                                        <div class="skill-logo">                               
                                                <img src="assets/images/ic_badge.png" />
                                            </div>
                                            <span class="badge-desc">{{skill.name}}</span>
                                        </div>
                                        <p class="skill-content">{{skill.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </ng-container>
        </ng-template>
    </owl-carousel-o>
</div>