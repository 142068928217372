export const environment = {
    production: false,
	hmr: false,
	domainHint: 'dev.waldenu.edu',
	authService: 'microsoft.com',
	tenandId: 'a744b2fc-9cb1-4d03-8ea8-91043d5e1e66',
	cloudFunctionBaseUrl: 'https://us-central1-walden-clr-qa.cloudfunctions.net/walden/',
    firebase: {
		apiKey: "AIzaSyB2dvMPItVJLn07zKw85JJZuTQOhB8durg",
		authDomain: "walden-clr-qa.firebaseapp.com",
		databaseURL: "https://walden-clr-qa.firebaseio.com",
		projectId: "walden-clr-qa",
		storageBucket: "walden-clr-qa.appspot.com",
		messagingSenderId: "307448514866",
		appId: "1:307448514866:web:91c4a3bfa325cc98"
	},
	hash: {
		isFixedSalt : false
	},
	CLRShare : {
		clrUrl : 'https://qa-myrecord.waldenu.edu/',
		profileUrl : 'myprofile/',
		indicator : 'clr/',
		expiryDate : 60
	},
	waldenTempoUrl : 'https://mytempo.waldenu.edu/MyProgress'
};
