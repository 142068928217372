<section class="logout-wrapper">
  <div class="logout-bckgrnd-img"></div>
  <div class="logout-inner">
      <div class="logo-img">
          <img class="logo" src="assets/images/logo_white.svg">
      </div>        
      <p class="logout-content">
          {{errorMessage}}
      </p>
      <button class="btn-goBack" (click)="closeTab()">Close</button>
      <button class="btn-goBack" *ngIf="message" (click)="reloadPage()">Refresh</button>
  </div>
</section>