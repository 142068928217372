import { OnInit } from '@angular/core';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { Constants } from '../../util/constants/constants';
import { ActivatedRoute } from '@angular/router';
var ClrShareComponent = /** @class */ (function () {
    function ClrShareComponent(viewerService, constants, actRoute) {
        this.viewerService = viewerService;
        this.constants = constants;
        this.actRoute = actRoute;
        this.errors = {
            noRecords: false,
            text: ''
        };
        this.isLoading = true;
        this.showAcademicsSection = false;
    }
    /**
     * @description The component initialization
     * Note: will check if the user is logged in or not.
     * If yes, the details of the current user is fetched from the firebase auth
     * and is saved to the currentUser object.
     */
    ClrShareComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _self = this;
        localStorage.removeItem('backToCLR');
        this.errors.noRecords = false;
        this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
        this.actRoute.params.subscribe(function (params) {
            if (params) {
                _this.getCLRShareDetails(params.accessToken);
            }
        });
    };
    ClrShareComponent.prototype.getCLRShareDetails = function (token) {
        var _this = this;
        this.viewerService.getCLRShareDetails(token)
            .subscribe(function (result) {
            if (result) {
                _this.shareData = result;
                console.log(_this.shareData);
                _this.getDataForClrViewer(_this.shareData.uid, _this.shareData.snapshot);
            }
        });
    };
    /**
     * @description Subscribing to the data for binding the details
     * in the CLR Viewer.
     * Note: Once any update happens to the data in the firebase db,
     * the UI will be refreshed automatically with the new changes.
     */
    ClrShareComponent.prototype.getDataForClrViewer = function (currentUser, doc) {
        var _this = this;
        this.viewerService.getDataForCLRSnapShot(currentUser, doc)
            .subscribe(function (result) {
            _this.isLoading = result ? false : true;
            _this.issuers = result;
            _this.records = [];
            if (_this.issuers && (_this.issuers.records && _this.issuers.records.length > 0)) {
                _this.issuers.person.ex_userInputUrl === 'undefined' ? _this.issuers.person.ex_userInputUrl = '' : _this.issuers.person.ex_userInputUrl;
                _this.academics = _this.getSharedAcademicDetails(_this.issuers.transcriptEntities.academics);
                _this.showAcademicsSection = _this.academics.isActive ? true : false;
                _this.errors.noRecords = true;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_RECORD;
            }
            else if (!_this.issuers || _this.issuers.length === 0) {
                _this.errors.noRecords = false;
                _this.errors.text = _this.constants.VALUES.ERRORS.NO_DATA;
                _this.showAcademicsSection = false;
                _this.isLoading = false;
            }
        });
    };
    /**
     * @description filter the records which are hidden in the shared version
     * and show only the items which are made visible by the student.
     * @param records records to be formatted
     */
    ClrShareComponent.prototype.getSharedAcademicDetails = function (records) {
        records.programList.forEach(function (item) {
            item.courses.forEach(function (course) {
                course.competencies.forEach(function (competency) {
                    if (competency.isHidden) {
                        competency.subCompetencies = [];
                    }
                });
            });
        });
        return {
            isActive: records.isActive,
            programList: records.programList,
            skills: records.skills.filter(function (x) { return !x.isHidden; })
        };
    };
    return ClrShareComponent;
}());
export { ClrShareComponent };
