import { Component, OnInit, ɵConsole } from '@angular/core';
import { ClrViewerService } from '../../services/clr-viewer/clr-viewer.service';
import { DataMappingService } from '../../services/clr-viewer/data-mapping.service';
import * as firebase from 'firebase/app';
import { Constants } from '../../util/constants/constants';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/common/util.service';

@Component({
  selector: 'app-clr-builder',
  templateUrl: './clr-builder.component.html'
})
export class ClrBuilderComponent implements OnInit {

  public currentUser: any;
  public issuers;
  public records;
  public academics;
  public errors = {
    noRecords: false,
    text: ''
  };
  public isLoading = true;
  public savingSnapshot = false;
  public autoSyncError = false;
  public showAcademicsSection = false;
  public editVersionName = false;
  public version = {
    name: '',
    title: ''
  };


  constructor(
    private viewerService: ClrViewerService,
    private dataMappingService: DataMappingService,
    private constants: Constants,
    private toastr: ToastrService,
    private router: Router,
    private util: UtilService
  ) { }


  /**
   * @description The component initialization
   * Note: will check if the user is logged in or not.
   * If yes, the details of the current user is fetched from the firebase auth
   * and is saved to the currentUser object.
   */
  ngOnInit() {
    if(JSON.parse(sessionStorage.getItem('version'))) {
      this.version = JSON.parse(sessionStorage.getItem('version'));
    }
    var _self = this;
    localStorage.removeItem('backToCLR');
    this.errors.noRecords = false;
    this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        _self.currentUser = user;
        firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
          sessionStorage.setItem('idToken', idToken);
          _self.getDataForClrViewer(_self.currentUser.uid);
        });
      }
    });
  }

  /**
   * @description method to sync the data from salesforce to firestore.
   * Note: Once the user is logged in,
   * this will be called automatically or else he/she can do it manually too.
   */
  public syncData() {
    this.viewerService.syncData(this.constants.VALUES.MAPPING_ID, this.currentUser.email, response => {
    }, error => {
      this.toastr.error(this.constants.VALUES.ERRORS.SYNC.MESSAGE, this.constants.VALUES.ERRORS.SYNC.TYPE);
      this.autoSyncError = true;
    });
  }

  /**
   * @description Subscribing to the data for binding the details
   * in the CLR Viewer.
   * Note: Once any update happens to the data in the firebase db,
   * the UI will be refreshed automatically with the new changes.
   */
  public getDataForClrViewer(currentUser) {
    this.viewerService.getDataForCLRSnapShot(currentUser, this.version.title)
      .subscribe(result => {
        this.isLoading = result ? false : true;
        this.issuers = result;
        this.records = [];
        if (this.issuers && (this.issuers.records && this.issuers.records.length > 0)) {
          this.issuers.person.ex_userInputUrl === 'undefined' ? this.issuers.person.ex_userInputUrl = '' : this.issuers.person.ex_userInputUrl
          if (this.issuers.transcriptEntities && !this.issuers.transcriptEntities.academics) {
            this.issuers.transcriptEntities.academics = this.dataMappingService.mapAcademicsData(this.issuers);
          }
          this.academics = this.issuers.transcriptEntities.academics;
          this.showAcademicsSection = this.academics.isActive ? true : false;
          this.errors.noRecords = true;
          this.errors.text = this.constants.VALUES.ERRORS.NO_RECORD;
        } else if (!this.issuers || this.issuers.length === 0) {
          this.router.navigate(['/clr-viewer']);
          this.errors.noRecords = false;
          this.errors.text = this.constants.VALUES.ERRORS.NO_DATA;
          this.showAcademicsSection = false;
          this.isLoading = false;
        }
      });
  }

  /**
   * @description redirect to viewwer on clicking home icon in builder.
   */
  public redirctToCLRViewwer(){
    sessionStorage.setItem('fromBuilder', JSON.stringify(true));
    this.router.navigate(['/clr-viewer']);
  }

  /**
   * @description Copy the share url to clipboard
   * Note : User can paste this url to the browser and it will load the page.
   */
  public copyShareUrlToClipboard(newTab) {
    const accessToken = this.generateUniqueAccessToken();
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.constants.VALUES.CLR_SHARE.URL + accessToken;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.createNewClrShareDoc(accessToken, newTab);
  }

  /**
   * @description generate unique accesstoken for share url
   */
  public generateUniqueAccessToken() {
    const tokenString = `${this.issuers.person.studentId}-` +
      `${Date.now()}-` +
      `${this.issuers.issuer.name}-` +
      `${(this.issuers.person.givenName).toLocaleUpperCase()}-` +
      `${this.issuers.person.familyName.toLocaleUpperCase()}`;
    const uniqueToken = this.util.getSaltedHash(tokenString);
    return uniqueToken;
  }

  /**
   * @description save generatedtoken along with the user details in firestore
   */
  public createNewClrShareDoc(uniqueToken, newTab) {
    const doc = {
      createdate: new Date(),
      expiredate: this.issuers.snapshotExpiry,
      snapshot: this.issuers.snapshotTitle,
      uid: this.currentUser.uid
    };
    this.viewerService.setDataForCLRShare(this.currentUser.uid, uniqueToken, doc)
      .then(result => {
        if(newTab) {
          window.open(this.constants.VALUES.CLR_SHARE.URL + uniqueToken)
        } else {
          this.toastr.success(`<a>` + this.constants.VALUES.CLR_SHARE.URL + uniqueToken + `</a>`, this.constants.VALUES.SUCCESS.COPY.MESSAGE);
        }
      });
  }

  /**
   * @description save the snapshot version from builder
   */
  public saveSnapshotVersion() {
    this.savingSnapshot = true;
    this.viewerService.setDataForCLRViewer(this.currentUser.uid, this.issuers, this.issuers.snapshotTitle)
      .then(result => {
        this.savingSnapshot = false;
      });
  }

  public saveVersionName() {
    this.savingSnapshot = true;
    this.issuers.snapshotName = this.version.name;
    this.viewerService.setDataForCLRViewer(this.currentUser.uid, this.issuers, this.issuers.snapshotTitle)
      .then(result => {
        this.savingSnapshot = false;
        this.editVersionName = false;
      });
  }

  /**
   * @description refresh snapshot data from master.
   */
  public refreshSnapShotData(refreshSnapshot) {
    var _masterData;
    this.viewerService.getDataForCLRSnapShot(this.currentUser.uid, this.constants.VALUES.FIRESTORE_DB_CONSTANTS.MASTER)
      .subscribe(result => {
        if (result) {
          if(refreshSnapshot) {
            var _issuerData = {
              snapshotExpiry : this.issuers.snapshotExpiry,
              snapshotName : this.issuers.snapshotName,
              snapshotTitle : this.issuers.snapshotTitle,
              hideDOB: this.issuers.person.hideDOB ? this.issuers.person.hideDOB : false,
              hideEmail: this.issuers.person.hideEmail ? this.issuers.person.hideEmail : false,
              hidePhone: this.issuers.person.hidePhone ? this.issuers.person.hidePhone : false,
              hideStudentId: this.issuers.person.hideStudentId ? this.issuers.person.hideStudentId : false,
              hideUrl: this.issuers.person.hideUrl ? this.issuers.person.hideUrl : false,
            }
            this.issuers = result;
            this.issuers.lastTriggeredAt = this.util.setRefreshedDate(new Date());
            this.issuers.snapshotExpiry = this.util.setExpiryDate(new Date());
            this.issuers.snapshotName = _issuerData.snapshotName;
            this.issuers.snapshotTitle = _issuerData.snapshotTitle;
            this.issuers.person.hideDOB = _issuerData.hideDOB;
            this.issuers.person.hideEmail = _issuerData.hideEmail;
            this.issuers.person.hidePhone = _issuerData.hidePhone;
            this.issuers.person.hideStudentId = _issuerData.hideStudentId;
            this.issuers.person.hideUrl = _issuerData.hideUrl;
            this.issuers.transcriptEntities.academics = this.academics;
            this.saveSnapshotVersion();
            refreshSnapshot = false;
          }
        }
    });
  }

}

