import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/common/authentication.service';
import { Router } from '@angular/router';
var AuthenticationComponent = /** @class */ (function () {
    function AuthenticationComponent(auth, router) {
        this.auth = auth;
        this.router = router;
        this.isLoading = false;
    }
    AuthenticationComponent.prototype.ngOnInit = function () {
        var _self = this;
        _self.isLoading = JSON.parse(localStorage.getItem('backToCLR')) ? true : false;
    };
    /**
     * @description open the CLR viewer on successfull authentication.
     * Note: The authentication is done using microsoft AD and firebase.
     * User will be directed to the tenants login page and can login
     * by entering the username and password.
     */
    AuthenticationComponent.prototype.redirectToCLRViewer = function () {
        localStorage.setItem('backToCLR', JSON.stringify(true));
        this.router.navigate(['/clr-viewer']);
    };
    return AuthenticationComponent;
}());
export { AuthenticationComponent };
