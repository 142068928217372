import { environment } from '../../../environments/environment';
var APIConstants = /** @class */ (function () {
    function APIConstants() {
        this.BASE_URL = environment.cloudFunctionBaseUrl;
        this.API_URL = {
            LOGGER: this.BASE_URL + 'rest/log',
            SYNC: this.BASE_URL + 'v1/rest/refresh/',
        };
    }
    APIConstants.prototype.generateSyncUrl = function (mappingId, email) {
        return this.API_URL.SYNC + mappingId + '/data/' + email;
    };
    return APIConstants;
}());
export { APIConstants };
